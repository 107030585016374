import moment from 'moment';
import React from 'react';
import Button from '../../common/button/Button';
import ItemCardLayout from '../../common/layout/ItemCardLayout';
import Modal from '../../common/modal/Modal';
import TaskTable from '../../common/table/TaskTable';
import TaskGroupItem from './TaskGroupItem';
import SelectInput from '../../common/input/SelectInput';
import { getOrderById } from '../../../utils/orderUtils';
import { TasksProps } from '../../../types/states';

type TaskListByDateGroupProps = {
  tasks: TasksProps[];
};

const TaskListByDateGroup = ({ tasks }: TaskListByDateGroupProps): JSX.Element => {
  const [filterValue, setFilterValue] = React.useState('');
  const [selectedBuyer, setSelectedBuyer] = React.useState('');
  const [selectedStyle, setSelectedStyle] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(() => moment().add(7, 'd').format('ll'));

  const taskFilterByDate = tasks?.filter((task) => {
    const selectCondition = moment().isSameOrAfter(selectedDate)
      ? moment(task.due_date).isBetween(selectedDate, moment().format('ll'))
      : moment(task.due_date).isBetween(moment().format('ll'), selectedDate);
    return selectCondition;
  });

  const buyerList = taskFilterByDate?.reduce((accum: string[], item) => {
    const itemOrder = getOrderById(item.order_id);
    if (accum.includes(itemOrder?.buyer || '')) {
      return accum;
    }
    if (!itemOrder?.buyer) {
      return accum;
    }
    return [...accum, itemOrder.buyer];
  }, []);

  const taskFilterByBuyerList = taskFilterByDate?.filter((task) =>
    getOrderById(task.order_id)?.buyer?.includes(selectedBuyer)
  );

  const styleList = taskFilterByBuyerList?.reduce((accum: string[], item) => {
    const itemOrder = getOrderById(item.order_id);
    if (accum.includes(itemOrder?.style || '')) {
      return accum;
    }
    if (!itemOrder?.style) {
      return accum;
    }
    return [...accum, itemOrder.style];
  }, []);

  const taskFilterByStyleList = taskFilterByBuyerList?.filter((task) =>
    getOrderById(task.order_id)?.style?.includes(selectedStyle)
  );
  // Filtering tasks by button's value
  const filterTasks = filterValue
    ? taskFilterByStyleList?.filter((task) => task.task_status === filterValue)
    : taskFilterByStyleList;

  // Groupping task by date
  const taskGroupByDate =
    filterTasks?.length > 0
      ? filterTasks.reduce((accum: { date: string; tasks: TasksProps[] }[], task) => {
          const date = moment(task.due_date).format('DD MMM, YYYY');

          // Creating a date if date not exist and adding task on it
          if (!accum.find((item) => item.date === date)) {
            return [
              ...accum,
              {
                date: date,
                tasks: [
                  {
                    ...task,
                  },
                ],
              },
            ];
          }

          // Added task in a exist date
          return accum.map((item) =>
            item.date === date
              ? {
                  date: date,
                  tasks: [
                    ...item.tasks,
                    {
                      ...task,
                    },
                  ],
                }
              : item
          );
        }, [])
      : [];

  const handleSelectedDate = ({ target }: any) => {
    const date = moment().add(target.value, 'd').format('ll');
    setSelectedDate(date);
  };

  return (
    <ItemCardLayout
      className="h-100"
      title="Task List"
      subtitle={
        <p className="mb-0 align-self-end cursor-pointer" onClick={() => setIsModalOpen(true)}>
          View All
        </p>
      }
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="filterButton mb-3">
          <Button
            size="sm"
            variant="outlined"
            noShadow
            countColor="primary"
            style={{ minWidth: 'unset' }}
            className="me-3"
            onClick={() => setFilterValue('')}
          >
            All
          </Button>
          <Button
            size="sm"
            variant="outlined"
            count={
              taskFilterByDate?.filter((task) => task.task_status === 'due').length || undefined
            }
            noShadow
            countColor="primary"
            style={{ minWidth: 'unset' }}
            className="me-3"
            onClick={() => setFilterValue('due')}
          >
            Due
          </Button>
          <Button
            size="sm"
            variant="outlined"
            count={
              taskFilterByDate?.filter((task) => task.task_status === 'completed').length ||
              undefined
            }
            noShadow
            countColor="success"
            style={{ minWidth: 'unset' }}
            className="me-3"
            onClick={() => setFilterValue('completed')}
          >
            Completed
          </Button>
          <Button
            size="sm"
            variant="outlined"
            count={
              taskFilterByDate?.filter((task) => task.task_status === 'missed').length || undefined
            }
            noShadow
            countColor="danger"
            style={{ minWidth: 'unset' }}
            onClick={() => setFilterValue('missed')}
          >
            Missed
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xxl-6 mb-2">
            <SelectInput
              size="sm"
              defaultValue={'next7Days'}
              options={[
                { value: '7', title: 'Next 7 Days' },
                { value: '30', title: 'Next 1 Month' },
                { value: '90', title: 'Next 3 Months' },
                { value: '-7', title: 'Previous 7 Days' },
                { value: '-30', title: 'Previous 1 Month' },
                { value: '-90', title: 'Previous 3 Months' },
              ]}
              // style={{ maxWidth: 160 }}
              onChange={handleSelectedDate}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-xxl-6 mb-2">
            <SelectInput
              size="sm"
              defaultValue={''}
              options={[
                { value: '', title: 'All Buyers' },
                ...(buyerList?.map((item, index) => ({
                  id: index,
                  value: item,
                  title: item,
                })) || []),
              ]}
              // style={{ maxWidth: 160 }}
              onChange={({ target }: any) => {
                setSelectedBuyer(target.value);
                setSelectedStyle('');
              }}
              className="me-3"
            />
          </div>
          <div className="col-sm-12 col-md-12 col-xxl-6 mb-2">
            <SelectInput
              size="sm"
              defaultValue={''}
              options={[
                { value: '', title: 'All Styles' },
                ...(styleList?.map((item, index) => ({
                  id: index,
                  value: item,
                  title: item,
                })) || []),
              ]}
              // style={{ maxWidth: 160 }}
              onChange={({ target }: any) => setSelectedStyle(target.value)}
            />
          </div>
        </div>
      </div>
      {/* {taskGroupByDate?.map(
        (task) =>
          task.date === moment().format("DD MMM, YYYY") && (
            <TaskGroupItem task={task} key={task.date} />
          )
      )} */}
      {/* {//console.log(taskGroupByDate)} */}
      {taskGroupByDate?.length > 0
        ? taskGroupByDate?.map((task) => <TaskGroupItem task={task} key={task.date} />)
        : 'There is not task to show.'}

      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        customStyles={{
          width: '100%',
          maxWidth: 1200,
          overflow: 'auto',
          maxHeight: '500px',
          height: '100%',
        }}
      >
        <TaskTable tasks={tasks} />
      </Modal>
    </ItemCardLayout>
  );
};

export default TaskListByDateGroup;
