import { CostSheetProps } from '../types/states';
import apiClient from './axios';

export async function getCostSheetApi(order_id: string): Promise<string> {
  return order_id
    ? apiClient
        .get(`/orders/${order_id}/costsheet/`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : {};
}

export async function addCostSheetApi(
  order_id: number,
  data: FormData
): Promise<{ data: CostSheetProps }> {
  return apiClient.post(`/orders/${order_id}/costsheet/`, data);
}
