import { all, put, takeEvery, call } from 'redux-saga/effects';
import {
  getPrimaryRawMaterialApi,
  deletePrimaryRawMaterialApi,
} from '../../services/primaryRawMaterialService';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import {
  loadPrimaryRawMaterial,
  deletePrimaryRawMaterial,
  setPrimaryRawMaterialState,
  setPrimaryRawMaterial,
  removePrimaryRawMaterial,
} from '../reducers/primaryRawMaterialReducer';

function* LOAD_PRIMARY_RAW_MATERIAL({ payload }: { payload: { id: number } }) {
  yield put(setPrimaryRawMaterialState({ loading: true }));

  const materials: Record<string, never> = yield call(() => getPrimaryRawMaterialApi(payload.id));

  if (materials) {
    yield put(setPrimaryRawMaterial(materials));
  } else {
    yield put(setPrimaryRawMaterial({}));
  }

  yield put(setPrimaryRawMaterialState({ loading: false }));
}

function* DELETE_PRIMARY_RAW_MATERIAL({
  payload,
}: {
  payload: { materialId: number; orderId: number };
}) {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this?')) {
    const material: Record<string, never> = yield call(() =>
      deletePrimaryRawMaterialApi(payload.orderId, payload.materialId)
    );
    if (material) {
      yield put(removePrimaryRawMaterial({ id: material.id }));
      triggerSuccessNotification('Successfully Deleted');
    } else {
      triggerErrorNotification('Failed to Delete');
    }
  }
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadPrimaryRawMaterial, LOAD_PRIMARY_RAW_MATERIAL),
    takeEvery(deletePrimaryRawMaterial, DELETE_PRIMARY_RAW_MATERIAL),
  ]);
}
