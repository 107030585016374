import { createSlice } from '@reduxjs/toolkit';

type SliceState = { loading: boolean; data: Record<string, any> };

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    data: {},
  } as SliceState,
  reducers: {
    setAuthState: (state, action) => ({ ...state, ...action.payload }),
    setAuth: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export const { setAuth, setAuthState } = authSlice.actions;

export default authSlice.reducer;
