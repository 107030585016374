import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getRFQApi } from '../../services/rfqService';
import { AuthProps } from '../../types/states';
import { loadRfq, setRfq, setRfqState } from '../reducers/rfqReducer';

function* LOAD_RFQ({ payload }: { payload: AuthProps }) {
  yield put(setRfqState({ loading: true }));

  const rfq: Record<string, never> = yield call(() => getRFQApi(payload));

  if (rfq) {
    yield put(setRfq(rfq));
  }

  yield put(setRfqState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadRfq, LOAD_RFQ)]);
}
