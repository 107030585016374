import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getNotesApi } from '../../services/noteService';
import { loadNotes, setNotes, setNotesState } from '../reducers/noteReducer';

function* LOAD_NOTES({ payload }: { payload: { orderId: number } }) {
  yield put(setNotesState({ loading: true }));
  const notes: Record<string, never> = yield call(() => getNotesApi(payload?.orderId));
  if (notes) {
    yield put(setNotes(notes));
  }

  yield put(setNotesState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadNotes, LOAD_NOTES)]);
}
