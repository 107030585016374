import { createSlice } from '@reduxjs/toolkit';

const primaryRawMaterialSlice = createSlice({
  name: 'primaryRawMaterial',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setPrimaryRawMaterialState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setPrimaryRawMaterial: (state, action) => ({ ...state, ...action.payload }),
    addPrimaryRawMaterial: (state, action) => {
      state.data.push(action.payload);
    },
    removePrimaryRawMaterial: (state, action) => {
      state.data = state?.data?.filter((item: { id: number }) => item.id !== action.payload.id);
    },
  },
});

export const {
  loadPrimaryRawMaterial = 'primaryRawMaterial/loadPrimaryRawMaterial',
  deletePrimaryRawMaterial = 'primaryRawMaterial/deletePrimaryRawMaterial',
  setPrimaryRawMaterialState,
  setPrimaryRawMaterial,
  addPrimaryRawMaterial,
  removePrimaryRawMaterial,
}: any = primaryRawMaterialSlice.actions;

export default primaryRawMaterialSlice.reducer;
