import { createSlice } from '@reduxjs/toolkit';

const itemSpecificationSlice = createSlice({
  name: 'itemSpecification',
  initialState: { loading: false, data: [] },
  reducers: {
    setItemSpecificationState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setItemSpecification: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadItemSpecification = 'itemSpecification/LOAD_ITEMSPECIFICATION',
  setItemSpecificationState,
  setItemSpecification,
}: any = itemSpecificationSlice.actions;

export default itemSpecificationSlice.reducer;
