import { MainSpecificationProps } from '../../types/states';
import apiClient from '../axios';

export async function getMainSpecificationApi(product_code: string): Promise<string> {
  return apiClient
    .get(`/main_specification/${product_code}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addMainSpecificationApi(
  product_code: string,
  data: FormData
): Promise<{ data: MainSpecificationProps }> {
  return apiClient.post(`/invoice/${product_code}`, data);
}

// export async function deleteChallanApi(order_id: number): Promise<{ data: ChallanProps }> {
//   return apiClient.delete(`/invoice/${order_id}`);
// }
