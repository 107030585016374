import { createSlice } from '@reduxjs/toolkit';

const accessoriesSlice = createSlice({
  name: 'accessories',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setAccessoriesState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setAccessories: (state, action) => ({ ...state, ...action.payload }),
    addAccessories: (state, action) => {
      state.data?.push(action.payload);
    },
    removeAccessories: (state, action) => {
      state.data = state?.data?.filter((item: { id: number }) => item.id !== action.payload.id);
    },
  },
});

export const {
  loadAccessories = 'accessories/loadAccessories',
  deleteAccessories = 'accessories/deleteAccessories',
  setAccessoriesState,
  setAccessories,
  addAccessories,
  removeAccessories,
}: any = accessoriesSlice.actions;

export default accessoriesSlice.reducer;
