import React, { lazy } from 'react';
import { SocketContext } from './libs/SockteContext';
import { basename } from './utils/constant';

const Routes = (): {
  path: string;
  Component: any;
  permission_name?: string;
  addProps: any;
  exect: boolean;
}[] => {
  const socket = React.useContext(SocketContext);

  return [
    {
      path: `${basename}/`,
      Component: lazy(() => import('./pages/DashboardPage')),
      exect: true,
      permission_name: 'dashboard',
      addProps: {},
    },
    {
      path: `${basename}/order-management/workorder`,
      Component: lazy(() => import('./pages/OrderListPage')),
      exect: true,
      addProps: { formtype: 'workorder' },
    },
    {
      path: `${basename}/order-management/sample`,
      Component: lazy(() => import('./pages/OrderListPage')),
      exect: true,
      addProps: { formtype: 'sample' },
    },
    {
      path: `${basename}/order-management/delivered`,
      Component: lazy(() => import('./pages/OrderListPage')),
      exect: true,
      addProps: { formtype: 'delivered' },
    },
    {
      path: `${basename}/order-management/request`,
      Component: lazy(() => import('./pages/OrderListPage')),
      exect: true,
      addProps: { formtype: 'request' },
    },
    {
      path: `${basename}/labelReference`,
      Component: lazy(() => import('./pages/LabelReferencePage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/configuration/:key`,
      Component: lazy(() => import('./pages/ConfigurationPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/quality-control/report`,
      Component: lazy(() => import('./pages/QualityControlReportPage')),
      exect: true,
      permission_name: 'qc_report',
      addProps: {},
    },
    {
      path: `${basename}/quality-control/data`,
      Component: lazy(() => import('./pages/QualityControlDataPage')),
      exect: true,
      permission_name: 'qc_data',
      addProps: {},
    },
    {
      path: `${basename}/cost-sheet/list`,
      Component: lazy(() => import('./pages/CostSheetPage')),
      exect: true,
      permission_name: 'cost_sheet',
      addProps: {},
    },
    {
      path: `${basename}/cost-sheet/data`,
      Component: lazy(() => import('./pages/ManageCostSheetPage')),
      exect: true,
      permission_name: 'cost_sheet',
      addProps: {},
    },
    {
      path: `${basename}/cost-sheet/costing`,
      Component: lazy(() => import('./pages/CostingPage')),
      exect: true,
      permission_name: 'costing',
      addProps: {},
    },
    {
      path: `${basename}/cost-sheet/rfq`,
      Component: lazy(() => import('./pages/MyRFQPage')),
      exect: true,
      permission_name: 'rfq',
      addProps: {},
    },
    {
      path: `${basename}/cost-sheet/inhousedates`,
      Component: lazy(() => import('./pages/InHouseDatesPage')),
      exect: true,
      permission_name: 'inhousedates',
      addProps: {},
    },
    {
      path: `${basename}/btb/limiter`,
      Component: lazy(() => import('./pages/BTBLimiterPage')),
      exect: true,
      permission_name: 'btb-limiter',
      addProps: {},
    },
    {
      path: `${basename}/btb/requests`,
      Component: lazy(() => import('./pages/BTBRequestsPage')),
      exect: true,
      permission_name: 'btb-requests',
      addProps: {},
    },
    {
      path: `${basename}/btb/generate`,
      Component: lazy(() => import('./pages/BTBGeneratePage')),
      exect: true,
      permission_name: 'btb-generate',
      addProps: {},
    },
    {
      path: `${basename}/production-status/report`,
      Component: lazy(() => import('./pages/ProductionStatusReportPage')),
      exect: true,
      permission_name: 'production_report',
      addProps: {},
    },
    {
      path: `${basename}/production-status/data`,
      Component: lazy(() => import('./pages/ProductionStatusDataPage')),
      exect: true,
      permission_name: 'production_data',
      addProps: {},
    },
    {
      path: `${basename}/kanban`,
      Component: lazy(() => import('./pages/KanbanPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/contacts`,
      Component: lazy(() => import('./pages/ContactPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/profile`,
      Component: lazy(() => import('./pages/ProfilePage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/orders`,
      Component: lazy(() => import('./pages/OrderListPage')),
      permission_name: 'order_list',
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/users`,
      Component: lazy(() => import('./pages/UserListPage')),
      permission_name: 'user_list',
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/settings`,
      Component: lazy(() => import('./pages/SettingsPage')),
      exect: true,
      permission_name: 'settings',
      addProps: {},
    },
    {
      path: `${basename}/initialConfiguration/companyOnboarding`,
      Component: lazy(() => import('./pages/CompanyOnboardingPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/initialConfiguration/userOnboarding`,
      Component: lazy(() => import('./pages/UserOnboardingPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/auth-verify/:token`,
      Component: lazy(() => import('./pages/AuthenticationPage')),
      exect: true,
      addProps: {},
    },

    {
      path: `${basename}/production/:step`,
      Component: lazy(() => import('./pages/ProductionShowPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/:step/:type/report`,
      Component: lazy(() => import('./pages/ProductionReportShowPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/report/production-monitor`,
      Component: lazy(() => import('./pages/ProductionMonitorPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/report/production-tracker`,
      Component: lazy(() => import('./pages/ProductionTrackerPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/report/factory-key-metric`,
      Component: lazy(() => import('./pages/FactoryKeyMetricsPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/report/buyer-cm-monitor`,
      Component: lazy(() => import('./pages/BuyerWiseCMMonitorPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/report/style-cm-monitor`,
      Component: lazy(() => import('./pages/StyleWiseCMMonitorPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/task-list`,
      Component: lazy(() => import('./components/pages/timeandaction/TimeAndActionForm')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/calendar`,
      Component: lazy(() => import('./pages/CalendarPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/bill_of_material`,
      Component: lazy(() => import('./pages/BillOfMaterialPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/note-list`,
      Component: lazy(() => import('./pages/NotesPage')),
      exect: true,
      addProps: {},
    },
    {
      path: `${basename}/my-rfqs`,
      Component: lazy(() => import('./pages/MyRFQPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/my-queries`,
      Component: lazy(() => import('./pages/MyQuriesPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/bill-of-material`,
      Component: lazy(() => import('./pages/MyQuriesPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/qc`,
      Component: lazy(() => import('./pages/QcListPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/production`,
      Component: lazy(() => import('./pages/ProductionListPage')),
      exect: true,
      addProps: { socket },
    },

    {
      path: `${basename}/challan/add_challan`,
      Component: lazy(() => import('./pages/ChallanAddPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/challan/challan_list`,
      Component: lazy(() => import('./pages/ChallanListPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/account/add_bill`,
      Component: lazy(() => import('./pages/AccountAddBillPage')),
      exect: true,
      addProps: { socket },
    },

    {
      path: `${basename}/account/bill_list`,
      Component: lazy(() => import('./pages/AccountBillListPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/gatepass/add_gate_pass`,
      Component: lazy(() => import('./pages/GatePassAddPage')),
      exect: true,
      addProps: { socket },
    },

    {
      path: `${basename}/gatepass/gate_pass_list`,
      Component: lazy(() => import('./pages/GatePassListPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/orderTracking/sample_tracking`,
      Component: lazy(() => import('./pages/SampleTrackingPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/orderTracking/in_hand_job`,
      Component: lazy(() => import('./pages/InhandJobPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/orderTracking/all_job`,
      Component: lazy(() => import('./pages/AllJobPage')),
      exect: true,
      addProps: { socket },
    },

    {
      path: `${basename}/orderTracking/work_tracking`,
      Component: lazy(() => import('./pages/WorkTrackingPage')),
      exect: true,
      addProps: { socket },
    },

    {
      path: `${basename}/raw-material/raw-material-category`,
      Component: lazy(() => import('./pages/RawMaterialCategoryPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/raw-material/raw-material-sub-category`,
      Component: lazy(() => import('./pages/RawMaterialSubCategoryPage')),
      exect: true,
      addProps: { socket },
    },
    {
      path: `${basename}/raw-material/raw-material-list`,
      Component: lazy(() => import('./pages/RawMaterialListPage')),
      exect: true,
      addProps: { socket },
    },
  ];
};

export { Routes };
