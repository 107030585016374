import { ChallanProps } from '../../types/states';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import apiClient from '../axios';

export async function getChallanApi(order_id: number): Promise<string> {
  return apiClient
    .get(`/invoice/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addChallanApi(
  order_id: number,
  data: FormData
): Promise<{ data: ChallanProps }> {
  return apiClient.post(`/invoice/${order_id}`, data);
}

export async function deleteChallanApi(order_id: number): Promise<{ data: ChallanProps }> {
  return apiClient.delete(`/invoice/${order_id}`);
}

export const updateChallanCache = async () => {
  const endpoint = `/invoice/list/reload/1`;

  apiClient
    .get(endpoint)
    .then((response) => {
      if (response) {
        triggerSuccessNotification('Challan Data Synced');
      }

      return response.data;
    })
    .catch(() => {
      triggerErrorNotification('Try again Later');
      return false;
    });
};
