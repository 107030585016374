import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getNotificationApi, getNotificationUnseenApi } from '../../services/notificationService';
import {
  loadNotification,
  loadUnseenNotification,
  setNotification,
  setNotificationState,
  setUnseenNotification,
} from '../reducers/notificationReducer';

function* LOAD_NOTIFICATION({ payload }: { payload: { id: number } }) {
  yield put(setNotificationState({ loading: true }));

  const notifications: Record<string, never> = yield call(() => getNotificationApi());

  if (notifications) {
    yield put(setNotification(notifications));
  }

  yield put(setNotificationState({ loading: false }));
}

function* LOAD_UNSEEN_NOTIFICATION({ payload }: { payload: { id: number } }) {
  const unseenNotifications: Record<string, never> = yield call(() =>
    getNotificationUnseenApi(payload.id)
  );

  if (unseenNotifications) {
    yield put(setUnseenNotification(unseenNotifications));
  }
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadNotification, LOAD_NOTIFICATION)]);
  yield all([takeEvery(loadUnseenNotification, LOAD_UNSEEN_NOTIFICATION)]);
}
