import { ConfigurationProps } from '../../types/states';
import apiClient from '../axios';

export async function getConfigurationApi(key: string): Promise<string> {
  const url = key == null ? '/configuration' : `/configuration/${key}`;
  return apiClient
    .get(url)
    .then((response) => {
      // //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addConfigurationApi(
  key: string,
  value: any
): Promise<{ data: ConfigurationProps }> {
  return apiClient.post(`/configuration`, { key: key, value: value });
}

export async function editConfigurationApi(
  key: string,
  value: any,
  id: any
): Promise<{ data: ConfigurationProps }> {
  return apiClient.put(`/configuration/id/${id}`, { key: key, value: value });
}

export async function deleteConfigurationApi(id: any): Promise<{ data: ConfigurationProps }> {
  return apiClient.delete(`/configuration/id/${id}`);
}
