import { createSlice } from '@reduxjs/toolkit';
import { OrderProps } from '../../../types/states';

const accountSlice = createSlice({
  name: 'customer_orders',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    setCustomerOrdersState: (state, action) => ({ ...state, ...action.payload }),
    setCustomerOrders: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  loadCustomerOrders = 'customer_orders/load',
  setCustomerOrders,
  setCustomerOrdersState,
}: any = accountSlice.actions;

export default accountSlice.reducer;
