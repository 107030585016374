import { createSlice } from '@reduxjs/toolkit';

const challanSlice = createSlice({
  name: 'challan',
  initialState: { loading: false, data: [] },
  reducers: {
    setChallanState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setChallan: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadChallan = 'challan/LOAD_CHALLAN',
  setChallanState,
  setChallan,
}: any = challanSlice.actions;

export default challanSlice.reducer;
