import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getColorBackSideApi } from '../../../services/trims/colorBackSideService';
import {
  loadColorBackSide,
  setColorBackSide,
  setColorBackSideState,
} from '../reducer/colorBackSideReducer';

function* LOAD_COLORBACKSIDE({ payload }: { payload: { orderId: number } }) {
  //console.log('Color Back Side Order_Id', payload.orderId);
  yield put(setColorBackSideState({ loading: true }));

  const colorBackSide: Record<string, never> = yield call(() =>
    getColorBackSideApi(payload.orderId)
  );
  if (colorBackSide) {
    yield put(setColorBackSide(colorBackSide));
  }

  yield put(setColorBackSideState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadColorBackSide, LOAD_COLORBACKSIDE)]);
}
