import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getToolsReportDataApi } from '../../services/toolsReportDataService';
import { loadToolsReportData, setToolsReportDataState } from '../reducers/toolsReportDataReducer';

function* LOAD_TOOLS_REPORT_DATA({ payload }: any) {
  yield put(setToolsReportDataState({ loading: true }));
  // @ts-ignore
  const toolsReportData = yield call(() => getToolsReportDataApi(payload?.step, payload?.type));
  if (toolsReportData) {
    yield put(setToolsReportDataState({ data: toolsReportData }));
  }
  yield put(setToolsReportDataState({ loading: false }));
}

export default function* rootSaga() {
  yield all([takeEvery(loadToolsReportData, LOAD_TOOLS_REPORT_DATA)]);
}
