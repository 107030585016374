import apiClient from './axios';

export async function getDashboardApi(options: any): Promise<string> {
  const endpoint = `/dashboard/buyer/${options.data[0]}/lc/${options.data[1]}/po/${options.data[2]}/style/${options.data[3]}`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
