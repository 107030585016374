import { PrimaryRawMaterialProps } from '../types/states';
import apiClient from './axios';

export async function getPrimaryRawMaterialApi(order_id: number): Promise<string> {
  return order_id
    ? apiClient
        .get(`/orders/${order_id}/materials/`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : {};
}

export async function addPrimaryRawMaterialApi(
  order_id: number,
  data: Record<string, never>
): Promise<{ data: PrimaryRawMaterialProps }> {
  return apiClient.post(`/orders/${order_id}/materials/`, data);
}

export async function deletePrimaryRawMaterialApi(
  order_id: number,
  materialId: number
): Promise<string> {
  return apiClient
    .delete(`/orders/${order_id}/materials/${materialId}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
