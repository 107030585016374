import { all, put, takeEvery, call } from 'redux-saga/effects';
import { updateTaskApi } from '../../services/tasksService';
import { getTasksStatusCountApi } from '../../services/tasksStatusCountService';
import { getActiveOrderId } from '../../utils/orderUtils';
import {
  loadTasksStatusCount,
  updateTasksStatusCount,
  setTasksStatusCount,
  setTasksStatusCountState,
} from '../reducers/tasksStatusCountReducer';

function* LOAD_TASKS_STATUS_COUNT({ payload }: { payload: { id: number } }) {
  yield put(setTasksStatusCountState({ loading: true }));
  const tasks: Record<string, never> = yield call(() => getTasksStatusCountApi(payload.id));

  if (tasks) {
    yield put(setTasksStatusCount({ count: tasks?.count, data: tasks?.data }));
  }

  yield put(setTasksStatusCountState({ loading: false }));
}

function* UPDATE_TASKS_STATUS_COUNT({
  payload,
}: {
  payload: { id: number; data: Record<string, never>; completeFunc: () => void };
}) {
  yield put(setTasksStatusCountState({ loading: true }));

  const task: Record<string, never> = yield call(() => updateTaskApi(payload.id, payload.data));

  if (task) {
    yield put({
      type: loadTasksStatusCount,
      payload: { id: getActiveOrderId() },
    });
    if (payload?.completeFunc) {
      payload?.completeFunc();
    }
  }

  yield put(setTasksStatusCountState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadTasksStatusCount, LOAD_TASKS_STATUS_COUNT),
    takeEvery(updateTasksStatusCount, UPDATE_TASKS_STATUS_COUNT),
  ]);
}
