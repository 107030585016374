import { createSlice } from '@reduxjs/toolkit';

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: { loading: false, data: [] },
  reducers: {
    setConfigurationState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setConfiguration: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  loadConfiguration = 'configuration/LOAD_CONFIGURATION',
  setConfigurationState,
  setConfiguration,
}: any = configurationSlice.actions;

export default configurationSlice.reducer;
