import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getUsersApi } from '../../services/usersService';
import { loadUsers, setUsers, setUsersState } from '../reducers/usersReducer';

function* LOAD_USERS({ id }: { id: number }) {
  yield put(setUsersState({ loading: true }));

  const users: Record<string, never> = yield call(getUsersApi);

  if (users) {
    yield put(setUsers({ activeUsers: id, data: users }));
  }

  yield put(setUsersState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadUsers, LOAD_USERS)]);
}
