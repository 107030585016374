import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    loading: false,
    data: [],
    unseenNotification: {},
  },
  reducers: {
    setNotificationState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setNotification: (state, action) => {
      return { ...state, ...action.payload, unseenNotification: { count: 0 } };
    },
    setUnseenNotification: (state, action) => {
      state.unseenNotification = action.payload;
    },
  },
});

export const {
  loadNotification = 'notification/LOAD_NOTIFICATION',
  loadUnseenNotification = 'notification/LOAD_UNSEEN_NOTIFICATION',
  setNotification,
  setNotificationState,
  setUnseenNotification,
}: any = notificationSlice.actions;

export const {} = notificationSlice.actions;

export default notificationSlice.reducer;
