import { QCProps } from '../../types/states';
import apiClient from '../axios';

export async function getQCApi(order_id: number): Promise<string> {
  {
    //console.log(order_id);
  }
  return apiClient
    .get(`/qcdetails/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addQCApi(order_id: number, data: FormData): Promise<{ data: QCProps }> {
  return apiClient.post(`/qcdetails/${order_id}`, data);
}

export async function deleteQCApi(order_id: number): Promise<{ data: QCProps }> {
  return apiClient.delete(`/qcdetails/${order_id}`);
}
