import { createSlice } from '@reduxjs/toolkit';

const rfqQuriesSlice = createSlice({
  name: 'queries',
  initialState: { loading: false, data: [] },
  reducers: {
    setRfqQuriesState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setRfqQuries: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadRfqQuries = 'rfq/LOAD_RFQ_QURIES',
  setRfqQuriesState,
  setRfqQuries,
}: any = rfqQuriesSlice.actions;

export default rfqQuriesSlice.reducer;
