import moment from 'moment';
import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '../../common/modal/Modal';
import TaskTable from '../../common/table/TaskTable';
import { TasksProps } from '../../../types/states';

type CalendarSectionProps = {
  taskData: TasksProps[];
};

const CalendarSection = ({ taskData }: CalendarSectionProps): JSX.Element => {
  const [singleDayTasks, setSingleDayTasks] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  // Mapping task's data for calendar
  const events: any[] = taskData?.map((task) => ({
    id: task.id,
    title: task.task_name.length > 10 ? `${task.task_name.substring(0, 10)}...` : task.task_name,
    start:
      moment(task.due_date).format('HH') === '23'
        ? moment(task.due_date).subtract(1, 'hours').format()
        : moment(task.due_date).format(),

    status: task.task_status,
    background: '#6daeff',
  }));

  // //console.log(events);

  // Manipulating calender's event output element
  const handleEventContent = (data: any) => {
    const status = data.event.extendedProps.status;
    let color = '#f30f07';

    if (status === 'due') {
      color = 'blue';
    }
    if (status === 'completed') {
      color = '#52ab57';
    }

    return (
      <div className="d-flex align-items-center">
        <div
          className={`fc-daygrid-event-dot`}
          style={{
            borderColor: color,
          }}
        ></div>
        <b style={{ marginRight: 5 }}>{data.timeText}m</b>
        <i>{data.event.title}</i>
      </div>
    );
  };

  // Runs when select a day
  const handleDateSelect = (event: any) => {
    setSingleDayTasks(event.start.toISOString());

    setModalOpen(true);
  };

  return (
    <>
      <FullCalendar
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dayMaxEvents
        selectable
        eventContent={handleEventContent}
        select={handleDateSelect}
      />
      <Modal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
        customStyles={{
          width: '100%',
          maxWidth: 1000,
          overflow: 'auto',
          maxHeight: '500px',
          height: '100%',
        }}
      >
        <div className="p-2">
          <TaskTable
            tasks={taskData?.filter((task) => {
              // //console.log(
              //   task,
              //   moment(task.due_date).format("DD-MM-YYYY"),
              //   moment(singleDayTasks).format("DD-MM-YYYY")
              // );
              return (
                moment(task.due_date).format('DD-MM-YYYY') ===
                moment(singleDayTasks).format('DD-MM-YYYY')
              );
            })}
          />
        </div>
      </Modal>
    </>
  );
};

export default CalendarSection;
