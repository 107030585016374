import apiClient from './axios';

export async function getTasksStatusCountApi(id: number): Promise<string> {
  return id
    ? apiClient
        .get(`/orders/${id}/tasks/filter/count`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
