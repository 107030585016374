// Main interceptor service

import axios from 'axios';
import { logout } from '../../utils/authUtils';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'omd/',
});

apiClient.interceptors.request.use((request) => {
  // request.headers.AccessToken = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMxMjY3NDE3LCJqdGkiOiJhMWM1NDRjNzk3N2Y0ODQ4ODJmYzM1ZGM1ZTJkYTQwMyIsInVzZXJfaWQiOiI5MmYwNDdmYy1mOWZlLTQwYTQtOGYyYy0wNGMzYmFjNDQ3NjQifQ.jpLBuFLzCedW3taj_vV8jMTTHALI5Xx74OP1CZ8XO5ofLcLqbWSay7Fpt7pAroBNDcrtxaSh_Ll-3yo8KDJPVhlCHxktEF0x5zjv12uVbeLxPVsnFo3oG6uJuspk49cmGhH0VZoWpHryr1s9pK3xAae5Rzota2co9LXNpgU22KMI35mcFtoQLDLLZOC93kqtAsRx64J64D3YhGQI4FIJoHSzsyJQqCYu78b3ZxH2Zxib7HEJwPpk5Mww6cv8mfp1HMkncAFLVaGk900mqESu1MOIjGSTOw6qLUjQpApHMP5mPrcb-ZDBJbJjxoivSKLohn2uBdXE9Z2Q03ws2-bkA9BV98qBvNtKNJQIs24qGORC3ahdZ1DrvCJyk8rWPfVuNL6vOKrzU3L0Akkl5ng85wyKwK2G21Y6t8UkMKKjfpbzVX9120SlzpPYJJsum7NA79slwIVA8gUFXWMQN4zfAlwOkmnm4kHUUchmrUosJiFOvaltSDc76fr2mK3fXCaeoGjGSdT4sDyykczeIf3jDFxQfxsMexzlGBDG5cybX_b9eCUTmB7LXBdNfp38Ua10TUCumDoEWfkVWU0acS9HZOFf0IHxYkHmn5FTNW8cvdMiMeRwg1GJridiaNuuy5tArSRBWDSAyvp-Orof98RNce8kecW3YRQOLBrmh3MnmxI`;
  const userString = localStorage.getItem('user') || '';
  const user = JSON.parse(userString);
  request.headers.Authorization = 'Bearer ' + user.access;
  // request.headers.Authorization =
  //   'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYzMjIyMDMzLCJqdGkiOiJkZjUwODZjMzQyNjA0NWU3YTU4ODQ0ZjQyMTNjYmU3NSIsInVzZXJfaWQiOjEsInVzZXJfbmFtZSI6bnVsbCwiZW1haWwiOiJxYUBtZXJjaGFudGJheS5jb20ifQ.VrwL1O6OyIqxOMPOXy6DllUydqVWq6J8_-okMAI8amkSDWp7n6wZKORynrnB25e9biJW79Q0GCl9FfNByB6Ux09mFoJdRV5qF49_N4DwrX8iRhlOv-zNu-MQVnLxXrprj59NijyJUY-mhj3uF-YgZariOCIVVx0kbAitRE57q_9-jLfSC8O6S1w1A54gKg-139DWL_LjigZhJNd4knyXZPijZPKDINYgymjNl21f0WRYUZcJiVCFE17y1YdfGqkpv-5feWe6IC77P5RUzHn_11XlqkOtHkRQN9zZk5el4y9_3IxOC86e5VKhfTuXLGmE0Ov1CqsE-1iIntzvWp_lOY0H63yCLu3_ohgu6puTNL5J1oA9RIn96cuxXpxm_W098WCx4H1gWT1ceyoYNzWe9jP8Sn5hjIWFPXYTHRaBXBqWzu8MyLVhvNxubaH_avNaWG3LCRyxwCzYpclo87zGO7Fu1QayQ9-n088vdP4WgUW8J1I11CSBLE5U9jB_fv1DFm2SKZYMDeMVUnIycahDxm2_VtQrWvrSOS0c5A7zuNX_CMSWbU79WjMj337Xj6oJ9CruwzlCwxEkt6LpDtULY1-lvyS52nKEvjgbTk_gzuYXbukHOgZephydyyLp-SXYLBmJgvSkc3jnEn4qJvc3sfY-2qkjEkf7-7CPOa4JP8c';

  return request;
});

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      logout();
    }
  }
);

export default apiClient;
