import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getDashboardApi } from '../../services/dashboardService';
import { loadDashboard, setDashboard, setDashboardState } from '../reducers/dashboardReducer';

function* LOAD_DASHBOARD({ payload }: Record<string, never>) {
  yield put(setDashboardState({ loading: true }));
  const item: Record<string, never> = yield call(() => getDashboardApi(payload));
  if (item) {
    yield put(setDashboard({ data: item }));
  }
  yield put(setDashboardState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadDashboard, LOAD_DASHBOARD)]);
}
