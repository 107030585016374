import { all } from '@redux-saga/core/effects';
// import httpSaga from "./sagas/httpCallSaga";
import orderSaga from './sagas/ordersSaga';
import todaysTasksSaga from './sagas/todaysTasksSaga';
import tasksByOrderSaga from './sagas/tasksByOrderSaga';
import tasksStatusCountSaga from './sagas/tasksStatusCountSaga';
import productionDataSaga from './sagas/productionDataSaga';
import productionToolsDataSaga from './sagas/productionToolsDataSaga';
import qualityControlDataSaga from './sagas/qualityControlDataSaga';
import tasksSaga from './sagas/tasksSaga';
import commentsSaga from './sagas/commentsSaga';
import threadsSaga from './sagas/threadsSaga';
import singleThreadSaga from './sagas/singleThreadSaga';
import stepsSaga from './sagas/stepsSaga';
import usersSaga from './sagas/usersSaga';
import usersByOrderSaga from './sagas/usersByOrderSaga';
import notificationSaga from './sagas/notificationSaga';
import costSheetSaga from './sagas/costSheetSaga';
import rfqSaga from './sagas/rfqSaga';
import rfqQuriesSaga from './sagas/rfqQuriesSaga';
import btbSaga from './sagas/btbSaga';
import primaryRawMaterialSaga from './sagas/primaryRawMaterialSaga';
import accessoriesSaga from './sagas/accessoriesSaga';
import commissionSaga from './sagas/commissionSaga';
import reports from './sagas/reportsSaga';
import dashboard from './sagas/dashboardSaga';
import toolsReportData from './sagas/toolsReportDataSaga';
import costSheets from './sagas/toolsCostSheetSaga';
import material from './sagas/materialSaga';
import noteSaga from './sagas/noteSaga';
import contactSaga from './sagas/contactSaga';
import messageSaga from './sagas/messageSaga';
import rfqmessageSaga from './sagas/rfqmessageSaga';
import generalInformationSaga from './trims/saga/generalInformationSaga';
import configurationSaga from './trims/saga/configurationSaga';
import challanSaga from './trims/saga/invoiceSaga';
import itemSpecificationSaga from './trims/saga/itemSpecificationSaga';
import orderStepSaga from './trims/saga/orderStepSaga';
import mainSpecificationSaga from './trims/saga/mainSpecificationSaga';
import colorFrontSideSaga from './trims/saga/colorFrontSideSaga';
import colorBackSideSaga from './trims/saga/colorBackSideSaga';
import qcSaga from './trims/saga/qcSaga';
import accountSaga from './trims/saga/accountSaga';
import productionSaga from './trims/saga/productionSaga';

export default function* rootSaga(): any {
  yield all([
    orderSaga(),
    todaysTasksSaga(),
    tasksByOrderSaga(),
    tasksStatusCountSaga(),
    productionDataSaga(),
    productionToolsDataSaga(),
    qualityControlDataSaga(),
    tasksSaga(),
    costSheets(),
    dashboard(),
    threadsSaga(),
    singleThreadSaga(),
    stepsSaga(),
    usersSaga(),
    toolsReportData(),
    commentsSaga(),
    usersByOrderSaga(),
    notificationSaga(),
    costSheetSaga(),
    rfqSaga(),
    rfqQuriesSaga(),
    btbSaga(),
    reports(),
    material(),
    primaryRawMaterialSaga(),
    accessoriesSaga(),
    commissionSaga(),
    noteSaga(),
    contactSaga(),
    messageSaga(),
    rfqmessageSaga(),
    generalInformationSaga(),
    configurationSaga(),
    challanSaga(),
    itemSpecificationSaga(),
    orderStepSaga(),
    mainSpecificationSaga(),
    colorFrontSideSaga(),
    colorBackSideSaga(),
    qcSaga(),
    accountSaga(),
    productionSaga(),
  ]);
}
