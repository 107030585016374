import { createSlice } from '@reduxjs/toolkit';

const rfqSlice = createSlice({
  name: 'rfq',
  initialState: { loading: false, data: [] },
  reducers: {
    setRfqState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setRfq: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { loadRfq = 'rfq/LOAD_RFQ', setRfqState, setRfq }: any = rfqSlice.actions;

export default rfqSlice.reducer;
