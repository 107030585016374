import { all, put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { getTasksByOrderApi } from '../../services/tasksByOrderService';
import { deleteTasksApi, updateTaskApi } from '../../services/tasksService';
import {
  deleteTasksByOrder,
  loadTasksByOrder,
  setTasksByOrder,
  setTasksByOrderState,
  updateTasksByOrder,
  updateTasksByOrderData,
  deleteTasksByOrderData,
} from '../reducers/tasksByOrderReducer';

function* LOAD_TASKS_BY_ORDER({ payload }: { payload: { id: number } }) {
  yield put(setTasksByOrderState({ loading: true }));

  const tasks: Record<string, never> = yield call(() => getTasksByOrderApi(payload.id));

  if (tasks) {
    yield put(setTasksByOrder(tasks));
  }

  yield put(setTasksByOrderState({ loading: false }));
}

function* UPDATE_TASKS_BY_ORDER({
  payload,
}: {
  payload: { id: number; data: Record<string, never>; onFinishUpdate: () => void };
}) {
  yield put(setTasksByOrderState({ loading: true }));

  const task: Record<string, never> = yield call(() => updateTaskApi(payload.id, payload.data));

  if (task) {
    yield put(updateTasksByOrderData(task));
    payload?.onFinishUpdate();
  }

  yield put(setTasksByOrderState({ loading: false }));
}
function* DELETE_TASKS_BY_ORDER({ payload }: any) {
  yield put(setTasksByOrderState({ loading: true }));

  const task: Record<string, never> = yield call(() => deleteTasksApi(payload));

  if (task) {
    yield put(deleteTasksByOrderData(task));
  }

  yield put(setTasksByOrderState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeLatest(loadTasksByOrder, LOAD_TASKS_BY_ORDER),
    takeEvery(updateTasksByOrder, UPDATE_TASKS_BY_ORDER),
    takeEvery(deleteTasksByOrder, DELETE_TASKS_BY_ORDER),
  ]);
}
