import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    data: [] as any,
  },
  reducers: {
    setUsersState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setUsers: (state, action) => ({ ...state, ...action.payload }),
    addUser: (state, action) => {
      if (state?.data?.find((item: { id: number }) => item?.id === action?.payload?.user?.id)) {
        state.data = state.data.map((item: { id: number }) =>
          item.id === action?.payload?.user?.id ? action.payload.user : item
        );
      } else {
        state.data.push(action.payload.user);
      }
    },
  },
});

export const {
  loadUsers = 'users/LOAD_USERS',
  setUsers,
  setUsersState,
  addUser,
}: any = usersSlice.actions;

export default usersSlice.reducer;
