import { ProductionDataProps } from '../types/states';
import apiClient from './axios';

export async function getProductionDataApi(id: number): Promise<string> {
  return id == 0
    ? { count: 0, data: [], steps: [] }
    : apiClient
        .get(`/orders/${id}/productions/`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        });
}

export async function addProductionDataApi(data: FormData): Promise<{ data: ProductionDataProps }> {
  return apiClient.post(`/productions/`, data);
}

export async function updateProductionDataApi(
  id: number,
  data: FormData
): Promise<{ data: ProductionDataProps }> {
  return apiClient.put(`/productions/${id}/`, data);
}

export async function deleteProductionDataApi(id: number): Promise<string> {
  return apiClient
    .delete(`/productions/${id}/`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addProductionStepsApi(data: Record<string, never>): Promise<string> {
  return apiClient.post(`/productionsteps/`, data);
}

export async function getStepsApi(): Promise<string> {
  const endpoint = '/productionsteps/';

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
