import { createSlice } from '@reduxjs/toolkit';

const toolsCostSheetSlice = createSlice({
  name: 'costSheet',
  initialState: { loading: false, data: [] },
  reducers: {
    setCostSheetState: (state: any, action: { payload: any }) => {
      return { ...state, ...action.payload };
    },
    setCostSheetData: (state: any, action: { payload: any }) => {
      let costSheets = [...action.payload];
      costSheets = costSheets.reduce((acc, item) => {
        acc[item.order] = { ...item };
        return acc;
      }, {});
      return { ...state, ...{ data: costSheets } };
    },
  },
});

export const {
  loadCostSheetData = 'costSheet/loadData',
  setCostSheetState,
  setCostSheetData,
}: any = toolsCostSheetSlice.actions;

export default toolsCostSheetSlice.reducer;
