import chatServerClient from './axios/chatServerClient';

export async function getThreadsApi(id: number, orderId: number): Promise<string> {
  return orderId
    ? chatServerClient
        .get(`/users/${id}/orders/${orderId}/conversations`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function getUnseenThreadsCount(id: number): Promise<string> {
  return id
    ? chatServerClient
        .get(`/users/${id}/messages/unseen`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}

export async function addThreadApi(data: Record<string, any>): Promise<string> {
  const endpoint = `/conversations/`;

  return chatServerClient
    .post(endpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addUserToGroupThreadsApi(
  orderId: number,
  conversationId: string,
  data: Record<string, never>
): Promise<string> {
  return orderId
    ? chatServerClient
        .post(`/orders/${orderId}/conversations/${conversationId}/members`, data)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}

export async function removeUserFromGroupThreadsApi(id: number, userId: number): Promise<string> {
  return id && userId
    ? chatServerClient
        .delete(`/conversations/${id}/members/${userId}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
