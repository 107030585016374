import { createSlice } from '@reduxjs/toolkit';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: { loading: false, data: [] as any[] },
  reducers: {
    setCommentsState: (state, action) => ({ ...state, ...action.payload }),
    setComments: (state, action) => ({ state, ...action.payload }),
    addComments: (state, action) => {
      state.data.push(action.payload);
    },
    // updateCommentsData: (state, action) => {
    //   state.data.data = state.data.data.map((comment) =>
    //     comment.id === action.payload.id ? { ...action.payload } : comment
    //   );
    // },
  },
});

export const {
  loadComments = 'comments/loadComments',
  // updateComments = "comments/updateComments",
  addComments,
  setCommentsState,
  // updateCommentsData,
  setComments,
}: any = commentsSlice.actions;

export default commentsSlice.reducer;
