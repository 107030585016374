import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getrfqMessageApi } from '../../services/rfqmessageService';
import { AuthProps, RFQProps } from '../../types/states';
import { loadrfqmessage, setrfqMessage, setrfqmessageState } from '../reducers/rfqmessageReducer';

function* LOAD_RFQ_MESSAGE({ payload }: { payload: { rfq_id: string; user_id: number } }) {
  yield put(setrfqmessageState({ loading: true }));
  const message: Record<string, never> = yield call(() => getrfqMessageApi(payload));
  if (message) {
    yield put(setrfqMessage(message));
  }

  yield put(setrfqmessageState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadrfqmessage, LOAD_RFQ_MESSAGE)]);
}
