import { all, put, takeEvery, call } from 'redux-saga/effects';
import {
  addBillApi,
  getAllBills,
  getBillsByCustomerApi,
  getOrdersByCustomer,
} from '../../../services/trims/accountService';
import { setOrder, setOrderState } from '../../reducers/orderReducer';
import { setOrders, setOrdersState } from '../../reducers/ordersReducers';
import {
  addAccount,
  loadAccount,
  loadAccounts,
  setAccount,
  setAccountState,
} from '../reducer/accountReducer';
import {
  loadCustomerOrders,
  setCustomerOrders,
  setCustomerOrdersState,
} from '../reducer/customerOrderReducer';

function* ADD_ACCOUNT({
  payload,
}: {
  payload: { cd: Record<string, any>; submitResponse: any; setAddDatabill: any; setViewOn: any };
}) {
  yield put(setAccountState({ loading: true }));

  const account: Record<string, never> = yield call(() =>
    addBillApi(payload.cd, payload.setAddDatabill, payload.setViewOn)
  );

  if (account) {
    yield put(setAccount(account));
    payload.submitResponse();
  }

  yield put(setAccountState({ loading: false }));
}

function* LOAD_ACCOUNT({ payload }: { payload: { key: string } }) {
  yield put(setAccountState({ loading: true }));

  const account: Record<string, never> = yield call(() => getBillsByCustomerApi(payload.key));

  if (account) {
    yield put(setAccount(account));
  }

  yield put(setAccountState({ loading: false }));
}

function* LOAD_CUSTOMER_ORDERS({
  payload,
}: {
  payload: { key: string; completeFunc: (e: any) => void; orders: any; reload: any };
}) {
  yield put(setCustomerOrdersState({ loading: true }));

  const order: Record<string, never> = yield call(() =>
    getOrdersByCustomer(payload.key, payload.orders)
  );

  if (order) {
    yield put(setCustomerOrders(order));
  }
  if (payload?.completeFunc) {
    payload?.completeFunc(order);
  }

  yield put(setCustomerOrdersState({ loading: false }));
}

function* LOAD_ACCOUNTs({
  payload,
}: {
  payload: { key: string; completeFunc: (e: any) => void; orders: any; reload: any };
}) {
  yield put(setAccountState({ loading: true }));

  const account: Record<string, never> = yield call(() => getAllBills(payload.reload));

  if (account) {
    yield put(setAccount(account));
  }

  yield put(setAccountState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadAccount, LOAD_ACCOUNT),
    takeEvery(loadAccounts, LOAD_ACCOUNTs),
    takeEvery(loadCustomerOrders, LOAD_CUSTOMER_ORDERS),
    takeEvery(addAccount, ADD_ACCOUNT),
  ]);
}
