import { createSlice } from '@reduxjs/toolkit';

const costSheetSlice = createSlice({
  name: 'costSheet',
  initialState: { loading: false, data: {} },
  reducers: {
    setCostSheetState: (state, action) => ({ ...state, ...action.payload }),
    setCostSheet: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  loadCostSheet = 'costSheet/loadCostSheet',
  setCostSheetState,
  setCostSheet,
}: any = costSheetSlice.actions;

export default costSheetSlice.reducer;
