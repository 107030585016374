import { AuthProps } from '../types/states';
import rfqClient from './axios/rfqClient';

export async function getRFQApi(payload: AuthProps): Promise<string> {
  return rfqClient
    .get(`quotation/user/${payload?.user?.id}/filter/null/page/1/limit/200`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
