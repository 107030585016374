import { createSlice } from '@reduxjs/toolkit';

const mainSpecificationSlice = createSlice({
  name: 'mainSpecification',
  initialState: { loading: false, data: [] },
  reducers: {
    setMainSpecificationState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setMainSpecification: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadMainSpecification = 'mainSpecification/LOAD_MAINSPECIFICATION',
  setMainSpecificationState,
  setMainSpecification,
}: any = mainSpecificationSlice.actions;

export default mainSpecificationSlice.reducer;
