import { createSlice } from '@reduxjs/toolkit';
import { NotesProps } from '../../types/states';

const notesSlice = createSlice({
  name: 'notes',
  initialState: { loading: false, data: [] } as { loading: boolean; data: NotesProps[] },
  reducers: {
    setNotesState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setNotes: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { loadNotes = 'notes/loadNotes', setNotesState, setNotes }: any = notesSlice.actions;

export default notesSlice.reducer;
