import { createSlice } from '@reduxjs/toolkit';

const activeChatSlice = createSlice({
  name: 'activeChat',
  initialState: {
    loading: false,
    data: { type: 'NONE', id: '0' } as { type: string; id: string },
    // type = DM | GM | RFQ
    // data = ContactProps | RFQProps
  },
  reducers: {
    setActiveChatState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setActiveChat: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { setActiveChat, setActiveChatState }: any = activeChatSlice.actions;

export default activeChatSlice.reducer;
