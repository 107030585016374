import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
  name: 'contacts',
  initialState: { loading: false, data: [] },
  reducers: {
    setContactState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setContact: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setActiveContact: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadContact = 'contacts/loadContact',
  setContactState,
  setContact,
}: any = messageSlice.actions;

export default messageSlice.reducer;
