import { createSlice } from '@reduxjs/toolkit';

const colorBackSideSlice = createSlice({
  name: 'colorBacktSide',
  initialState: { loading: false, data: [] },
  reducers: {
    setColorBackSideState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setColorBackSide: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadColorBackSide = 'colorBackSide/LOAD_COLORBACKSIDE',
  setColorBackSideState,
  setColorBackSide,
}: any = colorBackSideSlice.actions;

export default colorBackSideSlice.reducer;
