import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getFactoryKeyMetricsActivityStatusDataApi,
  getFactoryKeyMetricsOnTargetDataApi,
  getFactoryKeyMetricsProductionEfficiencyDataApi,
  getOutputRateApi,
  getOutputRateByBuyerApi,
  getProductionLastDateData,
  getProductionMonitoringDataApi,
  getProductionSummaryDataApi,
} from '../../services/reportService';
import {
  loadFactoryKeyMetricsActivityStatus,
  loadFactoryKeyMetricsOnTarget,
  loadFactoryKeyMetricsProductionEfficiency,
  loadOutputRate,
  loadOutputRateByBuyer,
  loadProductionLastDate,
  loadProductionMonitoringData,
  loadProductionSummaryData,
  setReports,
} from '../reducers/reportsReducer';

function* LOAD_FACTORY_KEY_METRICS_ON_TARGET() {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const factoryKeyMetricsOnTargetData = yield call(() => getFactoryKeyMetricsOnTargetDataApi());
  if (factoryKeyMetricsOnTargetData) {
    yield put(setReports({ onTarget: factoryKeyMetricsOnTargetData.orders }));
  }
  yield put(setReports({ loading: false }));
}

function* LOAD_FACTORY_KEY_METRICS_ACTIVITY_STATUS({ payload }: any) {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const factoryKeyMetricsActivityStatus = yield call(() =>
    getFactoryKeyMetricsActivityStatusDataApi({ ...payload })
  );

  if (factoryKeyMetricsActivityStatus) {
    yield put(
      setReports({
        activityStatus: factoryKeyMetricsActivityStatus,
      })
    );
  }

  yield put(setReports({ loading: false }));
}
function* LOAD_FACTORY_KEY_METRICS_PRODUCTION_EFFICIENCY({ payload }: any) {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const factoryKeyMetricsProductionEfficiency = yield call(() =>
    getFactoryKeyMetricsProductionEfficiencyDataApi({ ...payload })
  );

  if (factoryKeyMetricsProductionEfficiency) {
    yield put(
      setReports({
        productionEfficiency: factoryKeyMetricsProductionEfficiency,
      })
    );
  }

  yield put(setReports({ loading: false }));
}

function* LOAD_PRODUCTION_MONITORING_DATA({ payload }: any) {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const productionMonitoring = yield call(() => getProductionMonitoringDataApi({ ...payload }));

  if (productionMonitoring) {
    yield put(
      setReports({
        productionMonitoring,
      })
    );
  }

  yield put(setReports({ loading: false }));
}

function* LOAD_PRODUCTION_SUMMARY_DATA({ payload }: any) {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const productionSummary = yield call(() => getProductionSummaryDataApi({ ...payload }));

  if (productionSummary) {
    yield put(
      setReports({
        productionSummary,
      })
    );
  }

  yield put(setReports({ loading: false }));
}

function* LOAD_OUTPUT_RATE() {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const outputRate = yield call(() => getOutputRateApi());

  if (outputRate) {
    yield put(
      setReports({
        outputRate,
      })
    );
  }

  yield put(setReports({ loading: false }));
}

function* LOAD_OUTPUT_RATE_BY_BUYER() {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const outputRateByBuyer = yield call(() => getOutputRateByBuyerApi());

  if (outputRateByBuyer) {
    yield put(
      setReports({
        outputRateByBuyer,
      })
    );
  }

  yield put(setReports({ loading: false }));
}

function* LOAD_PRODUCTION_LAST_DATE() {
  yield put(setReports({ loading: true }));
  // @ts-ignore
  const productionLastDate = yield call(() => getProductionLastDateData());

  if (productionLastDate) {
    yield put(
      setReports({
        productionLastDate: productionLastDate.reduce((accum: any, item: any) => {
          accum[item.order] = item;
          return accum;
        }, {}),
      })
    );
  }

  yield put(setReports({ loading: false }));
}

export default function* rootSaga() {
  yield all([
    takeEvery(loadFactoryKeyMetricsOnTarget, LOAD_FACTORY_KEY_METRICS_ON_TARGET),
    takeEvery(
      loadFactoryKeyMetricsProductionEfficiency,
      LOAD_FACTORY_KEY_METRICS_PRODUCTION_EFFICIENCY
    ),
    takeEvery(loadFactoryKeyMetricsActivityStatus, LOAD_FACTORY_KEY_METRICS_ACTIVITY_STATUS),
    takeEvery(loadProductionMonitoringData, LOAD_PRODUCTION_MONITORING_DATA),
    takeEvery(loadProductionSummaryData, LOAD_PRODUCTION_SUMMARY_DATA),
    takeEvery(loadOutputRate, LOAD_OUTPUT_RATE),
    takeEvery(loadOutputRateByBuyer, LOAD_OUTPUT_RATE_BY_BUYER),
    takeEvery(loadProductionLastDate, LOAD_PRODUCTION_LAST_DATE),
  ]);
}
