import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getUsersByOrderApi } from '../../services/usersService';
import {
  loadUsersByOrder,
  setUsersByOrder,
  setUsersByOrderState,
} from '../reducers/usersByOrderReducer';

function* LOAD_USERS_BY_ORDER({ payload }: { payload: { orderId: number } }) {
  yield put(setUsersByOrderState({ loading: true }));
  const usersByOrder: Record<string, never> = yield call(() => getUsersByOrderApi(payload.orderId));

  if (usersByOrder) {
    yield put(setUsersByOrder(usersByOrder));
  }

  yield put(setUsersByOrderState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadUsersByOrder, LOAD_USERS_BY_ORDER)]);
}
