// Main interceptor service

import axios from 'axios';
import { logout } from '../../utils/authUtils';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'tools/',
});

apiClient.interceptors.request.use((request) => {
  // request.headers.AccessToken = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMxMjY3NDE3LCJqdGkiOiJhMWM1NDRjNzk3N2Y0ODQ4ODJmYzM1ZGM1ZTJkYTQwMyIsInVzZXJfaWQiOiI5MmYwNDdmYy1mOWZlLTQwYTQtOGYyYy0wNGMzYmFjNDQ3NjQifQ.jpLBuFLzCedW3taj_vV8jMTTHALI5Xx74OP1CZ8XO5ofLcLqbWSay7Fpt7pAroBNDcrtxaSh_Ll-3yo8KDJPVhlCHxktEF0x5zjv12uVbeLxPVsnFo3oG6uJuspk49cmGhH0VZoWpHryr1s9pK3xAae5Rzota2co9LXNpgU22KMI35mcFtoQLDLLZOC93kqtAsRx64J64D3YhGQI4FIJoHSzsyJQqCYu78b3ZxH2Zxib7HEJwPpk5Mww6cv8mfp1HMkncAFLVaGk900mqESu1MOIjGSTOw6qLUjQpApHMP5mPrcb-ZDBJbJjxoivSKLohn2uBdXE9Z2Q03ws2-bkA9BV98qBvNtKNJQIs24qGORC3ahdZ1DrvCJyk8rWPfVuNL6vOKrzU3L0Akkl5ng85wyKwK2G21Y6t8UkMKKjfpbzVX9120SlzpPYJJsum7NA79slwIVA8gUFXWMQN4zfAlwOkmnm4kHUUchmrUosJiFOvaltSDc76fr2mK3fXCaeoGjGSdT4sDyykczeIf3jDFxQfxsMexzlGBDG5cybX_b9eCUTmB7LXBdNfp38Ua10TUCumDoEWfkVWU0acS9HZOFf0IHxYkHmn5FTNW8cvdMiMeRwg1GJridiaNuuy5tArSRBWDSAyvp-Orof98RNce8kecW3YRQOLBrmh3MnmxI`;
  const userString = localStorage.getItem('user') || '';
  const user = JSON.parse(userString);
  request.headers.Authorization = 'Bearer ' + user.access;
  // request.headers.Authorization =
  //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM4ODY1MTg1LCJqdGkiOiIwYTY5ZmZiZWQzZGE0ODMzODAwNGUzMDcwMmZlNzEyYyIsInVzZXJfaWQiOjEsImNvbXBhbnlfaWQiOjEsImlzX2FkbWluIjp0cnVlLCJ1c2VyX25hbWUiOiJrYWRlclgifQ.pABId6voLyTIY4K51tNnNDm_3u5cz_XSlfJZwx2p5LU_vAtKR6zqZz3KSH1Qsma6KpDGwlIjMJCp_Ot3Gv1bN1aXgDgZ0lQB6SAsO4H-olXxeVhjN0sPwHNG6RRKMdYs_kvdHrWJAiyJVjpmNI76IiOnlqHHoMlaYHBS2ywvbJ5NBa42Ur9BUvgsc3YYrydA3Qsy7ne7SmOT_bIJy3AvuTz5vQitVTlf0M8fsb_CMasl2nskstzqSstI2-Sk4xoy0NWiEMFa50HKfBfdU9i5nI95lS4SSyC03oF01KGJs1zgGRpkoO6lmrmK3aYySA8BRVN9LInhRrseqnMhIuw20k9g5IKTtxm04_N-f6BXpImsaTm4c0AxNIVwwFIT-iuWn8TpoOVkbNB69lThKlIMe3epjFVVzCWAQ98h7VDryhDIbR6e8f_rLdNUwVp9KFZIEq3q7pU_NP5EpHOP0-WuJ5nXMPTGlFL33iqRWgBR0y8pzyh-pAKogQ0ENddRShbLpHdZXBXcJSMia5viNAn-Fi_OUXBFSGfnuCOYeUlOmBZhh0oFg7y3zr-7f4veWdM-PkyYyGIFQXDqpqMjWluzxXiCfWUvNhG52bBh1BtQpKovSInVYVIgyzcm6aMBKJncf9ATXkrbNaVh5ddGZRQLOrf4o74xPCvfdWK3xyPK9DE";

  return request;
});

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      logout();
    }
  }
);

export default apiClient;
