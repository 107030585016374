import { GeneralInformationProps } from '../../types/states';
import apiClient from '../axios';

export async function getGeneralInformationApi(order_id: number): Promise<string> {
  return apiClient
    .get(`/orderinfo/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addGeneralInformationApi(
  data: FormData
): Promise<{ data: GeneralInformationProps }> {
  return apiClient.post(`/orderinfo/`, data);
}
