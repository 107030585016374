import { all, put, takeEvery, call } from 'redux-saga/effects';
import { deleteCommissionApi, getcommissionApi } from '../../services/commissionService';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import {
  loadCommission,
  deleteCommission,
  setCommissionState,
  setCommission,
  removeCommission,
} from '../reducers/commissionReducer';

function* LOAD_COMMISSION({ payload }: { payload: { id: number } }) {
  yield put(setCommissionState({ loading: true }));

  const commission: Record<string, never> = yield call(() => getcommissionApi(payload.id));

  if (commission) {
  } else {
    yield put(setCommission({}));
  }

  yield put(setCommissionState({ loading: false }));
}

function* DELETE_COMMISSION({ payload }: { payload: { orderId: number; commissionId: number } }) {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this?')) {
    const commission: Record<string, never> = yield call(() =>
      deleteCommissionApi(payload.orderId, payload.commissionId)
    );
    if (commission) {
      yield put(removeCommission({ id: commission.id }));
      triggerSuccessNotification('Successfully Deleted');
    } else {
      triggerErrorNotification('Failed to delete');
    }
  }
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadCommission, LOAD_COMMISSION),
    takeEvery(deleteCommission, DELETE_COMMISSION),
  ]);
}
