import { createSlice } from '@reduxjs/toolkit';

const toolsReportDataSlice = createSlice({
  name: 'toolsReportData',
  initialState: { loading: false, data: [] },
  reducers: {
    setToolsReportDataState: (state: any, action: { payload: { data: any } }) => {
      return { ...state, ...action.payload.data };
    },
  },
});

export const { loadToolsReportData = 'toolsReportData/load', setToolsReportDataState }: any =
  toolsReportDataSlice.actions;

export default toolsReportDataSlice.reducer;
