import { createSlice } from '@reduxjs/toolkit';

const btbSlice = createSlice({
  name: 'btb',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setBTBState: (state, action) => ({ ...state, ...action.payload }),
    setBTB: (state, action) => ({ ...state, ...action.payload }),
    addBTB: (state, action) => {
      state.data.push(action.payload);
    },
    updateBTB: (state, action) => {
      state.data = state.data.map((item: { id: number }) =>
        item.id === action.payload.id ? action.payload.data : item
      );
      // state.data.data.unshift(action.payload);
    },
    removeBTBFile: (state, { payload }) => {
      state.data = state.data.map((item: { id: number; files: Record<string, never>[] }) => {
        if (item.id === payload.item.id) {
          return {
            ...item,
            files: item.files.filter((data) => data.id !== payload.id),
          };
        }
        return item;
      });
    },
  },
});

export const {
  loadBTB = 'btb/LOAD_BTB',
  setBTBState,
  setBTB,
  addBTB,
  updateBTB,
  removeBTBFile,
}: any = btbSlice.actions;

export default btbSlice.reducer;
