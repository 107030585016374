import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getConfigurationApi } from '../../../services/trims/configurationService';
import {
  loadConfiguration,
  setConfiguration,
  setConfigurationState,
} from '../../trims/reducer/configurationReducer';

function* LOAD_CONFIGURATION({ payload }: { payload: { key: string } }) {
  // //console.log(payload.id);
  yield put(setConfigurationState({ loading: true }));

  const configuration: Record<string, never> = yield call(() => getConfigurationApi(payload.key));
  if (configuration) {
    yield put(setConfiguration(configuration));
  }

  yield put(setConfigurationState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadConfiguration, LOAD_CONFIGURATION)]);
}
