import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { updateTasks } from '../../../store/reducers/tasksReducer';
import { TasksProps } from '../../../types/states';
import { getOrderById } from '../../../utils/orderUtils';
import Button from '../../common/button/Button';

type TaskGroupItemProps = {
  task: { date: string; tasks: TasksProps[] };
  dispatch: any;
};

const TaskGroupItem = ({ task, dispatch }: TaskGroupItemProps) => {
  const groupByOrder = task?.tasks?.reduce(
    (accum: { id: string; tasks: TasksProps[]; [key: string]: any }[], item) => {
      let indexOf = 0;
      accum.find((data, index) => {
        if (data.id === item.order_id) {
          indexOf = index;
          return true;
        }
        return false;
      });
      if (indexOf) {
        accum[indexOf] = {
          id: item.order_id,
          tasks: [...accum[indexOf].tasks, item],
        };
        return accum;
      }
      return [...accum, { id: item.order_id, tasks: [item] }];
    },
    []
  );
  const handleComplete = (data: TasksProps) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to ${
          !data.is_completed ? 'complete the task' : 'make the task due'
        }?`
      )
    ) {
      dispatch({
        type: updateTasks,
        payload: { id: data.id, data: { is_completed: !data.is_completed } },
      });
    }
  };

  return (
    <div className="taskGroupItem">
      <h5 className="date mb-2 text-center">{task.date}</h5>
      {groupByOrder.map((item) => (
        <React.Fragment key={item.id}>
          <div
            className="d-flex justify-content-between"
            // style={{ paddingLeft: 96 }}
          >
            <h6 className="mb-2">
              {getOrderById(item.id)?.buyer} - {getOrderById(item.id)?.style}
            </h6>
            {/* <p className="text-black-50" style={{ fontSize: 12 }}>
              {moment
                .duration(
                  moment(getOrderById(item.id)?.shipping_date).diff(moment())
                )
                .days()}{" "}
              days remaining
            </p> */}
          </div>
          {item.tasks.map((data, index) => (
            <div
              className={`taskData ${item.tasks?.length - 1 > index ? 'mb-2' : 'mb-4'}`}
              key={data.id}
            >
              {/* {//console.log(item.tasks)} */}
              <div className="time pe-2">{moment(data.due_date).format('hh:mm a')}</div>
              <div className="name fw-medium px-3">
                {data.task_name.length > 30
                  ? `${data.task_name.substring(0, 30)}...`
                  : data.task_name}
              </div>
              <div className="actions">
                <span className="me-3">{data.is_completed ? 'Done' : 'Due'}</span>

                {data.is_completed ? (
                  <Button
                    size="sm"
                    color="primary"
                    className="px-12 ms-1"
                    icon
                    noShadow
                    onClick={() => handleComplete(data)}
                  >
                    <i className="fas fa-undo-alt"></i>
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    color="theme-primary"
                    className="px-12 ms-1"
                    icon
                    noShadow
                    onClick={() => handleComplete(data)}
                  >
                    <i className="fas fa-check"></i>
                  </Button>
                )}
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default connect()(TaskGroupItem);
