import React, { InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface ISelectInputProps extends Partial<InputHTMLAttributes<HTMLSelectElement>> {
  shadow?: 'md' | '';
  className?: string;
  options?:
    | { key?: string; value: string | number; title: string; name?: string }[]
    | Record<string, never>[];
  defaultPlaceholder?: string | number;
  size?: 'sm' | 'md' | any;
  variant?: 'outlined' | '';
  register?: UseFormRegisterReturn;
  onChange?: any;
}

// Options: [{ id: 1, value: "merchandiser", title: "Merchandiser" }]
const SelectInput = React.forwardRef(
  (
    {
      shadow = '',
      className = '',
      options,
      register,
      defaultPlaceholder,
      size = 'md',
      variant = 'outlined',
      onChange,
      ...props
    }: ISelectInputProps,
    ref: React.Ref<HTMLSelectElement>
  ) => {
    return (
      <select
        className={`form-select selectInput ${className} shadow-${shadow} size-${size} variant-${variant}`}
        {...register}
        ref={ref}
        onChange={onChange}
        {...props}
      >
        {defaultPlaceholder && (
          <option value="" className="text-gray" disabled>
            {defaultPlaceholder}
          </option>
        )}
        {options?.map((option) => (
          <option key={option.key || option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    );
  }
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
