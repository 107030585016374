import apiClient from './axios/toolsClient';

export async function getFactoryKeyMetricsOnTargetDataApi() {
  return apiClient
    .get('/reports/factory-key-matrics/ontargets')
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getFactoryKeyMetricsActivityStatusDataApi({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  return apiClient
    .get(`/reports/factory-key-matrics/activity-status/start_date/${startDate}/end_date/${endDate}`)
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getFactoryKeyMetricsProductionEfficiencyDataApi({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  return apiClient
    .get(
      `/reports/factory-key-matrics/production-efficiency/start_date/${startDate}/end_date/${endDate}`
    )
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getProductionStepsReportDataApi({
  startDate,
  endDate,
  selectedStep = 'knitting',
  buyer = 'all',
  style = 'all',
}: {
  startDate: string;
  endDate: string;
  selectedStep: string;
  buyer: string;
  style: string;
}) {
  return apiClient
    .get(
      `/reports/production_report/step/${selectedStep}/buyer/${buyer}/style/${style}/start_date/${startDate}/end_date/${endDate}`
    )
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getProductionMonitoringDataApi({
  startDate = 'all',
  endDate = 'all',
  buyer = 'all',
  style = 'all',
}) {
  return apiClient
    .get(
      `/reports/production_monitoring/buyer/${buyer ? buyer : 'all'}/style/${
        style ? style : 'all'
      }/start_date/${startDate}/end_date/${endDate}`
    )
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getProductionSummaryDataApi({
  startDate = 'all',
  endDate = 'all',
  buyer = 'all',
  style = 'all',
}) {
  return apiClient
    .get(
      `/reports/production_summary/buyer/${buyer ? buyer : 'all'}/style/${
        style ? style : 'all'
      }/start_date/${startDate}/end_date/${endDate}`
    )
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getOutputRateApi() {
  return apiClient
    .get(`/reports/cm-monitor/output-rate/groupby/order`)
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getOutputRateByBuyerApi() {
  return apiClient
    .get(`/reports/cm-monitor/output-rate/groupby/buyer`)
    .then((resp) => resp.data)
    .catch(() => false);
}

export async function getProductionLastDateData() {
  return apiClient
    .get(`/reports/production-tracker/production/last-date`)
    .then((resp) => resp.data)
    .catch(() => false);
}
