import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getRfqQuriesApi } from '../../services/rfqQuriesService';
import { AuthProps } from '../../types/states';
import { loadRfqQuries, setRfqQuries, setRfqQuriesState } from '../reducers/rfqQuriesReducer';

function* LOAD_RFQ_QURIES({ payload }: { payload: AuthProps }) {
  yield put(setRfqQuriesState({ loading: true }));

  const rfqQuries: Record<string, never> = yield call(() => getRfqQuriesApi(payload));

  if (rfqQuries) {
    yield put(setRfqQuries(rfqQuries));
  }

  yield put(setRfqQuriesState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadRfqQuries, LOAD_RFQ_QURIES)]);
}
