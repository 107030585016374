import { createSlice } from '@reduxjs/toolkit';

const productionDataSlice = createSlice({
  name: 'productionDataTools',
  initialState: { loading: false, data: [] },
  reducers: {
    // @ts-ignore
    setProductionState: (state, action) => {
      return { ...state, ...action.payload };
    },
    addProductionDataState: (state: { data: any[] }, action: { payload: any }) => {
      state.data.unshift(action.payload);
    },
    updateProductionDataState: (
      state: { data: any[] },
      action: { payload: { productionId: any; data: any } }
    ) => {
      state.data = state.data.map((production) =>
        production.id === action.payload.productionId ? { ...action.payload.data } : production
      );
    },
    removeProductionDataState: (
      state: { data: any[] },
      action: { payload: { productionId: any } }
    ) => {
      state.data = state.data.filter((production) => production.id !== action.payload.productionId);
    },
  },
});

export const {
  loadStepProductionData = 'productionData/stepLoad',
  addToolsProductionData = 'productionDataTools/add',
  editToolsProductionData = 'productionDataTools/edit',
  deleteToolsProductionData = 'productionDataTools/delete',
  setProductionState,
  addProductionDataState,
  updateProductionDataState,
  removeProductionDataState,
}: any = productionDataSlice.actions;

export default productionDataSlice.reducer;
