import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getGeneralInformationApi } from '../../../services/trims/generalInformationService';
import {
  loadGeneralInformation,
  setGeneralInformation,
  setGeneralInformationState,
} from '../../trims/reducer/generalInformationReducer';

function* LOAD_GENERALINFORMATION({ payload }: { payload: { orderId: number } }) {
  //console.log(payload);
  yield put(setGeneralInformationState({ loading: true }));

  const generalInformation: Record<string, never> = yield call(() =>
    getGeneralInformationApi(payload.orderId)
  );
  if (generalInformation) {
    yield put(setGeneralInformation(generalInformation));
  }

  yield put(setGeneralInformationState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadGeneralInformation, LOAD_GENERALINFORMATION)]);
}
