import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  addToolsProductionDataApi,
  deleteToolsProductionDataApi,
  editToolsProductionDataApi,
  getToolsProductionDataApi,
} from '../../services/productionToolsDataService';
import { triggerSuccessNotification } from '../../utils/notifications';
import {
  addProductionDataState,
  addToolsProductionData,
  deleteToolsProductionData,
  editToolsProductionData,
  loadStepProductionData,
  removeProductionDataState,
  setProductionState,
  updateProductionDataState,
} from '../reducers/productionDataReducerTools';

function* LOAD_PRODUCTION_DATA({ payload }: any) {
  yield put(setProductionState({ loading: true }));
  // @ts-ignore
  const productionDatas = yield call(() => getToolsProductionDataApi(payload?.step, payload));
  if (productionDatas) {
    yield put(setProductionState({ data: productionDatas }));
  }
  yield put(setProductionState({ loading: false }));
}

function* ADD_PRODUCTION_DATA({ payload }: any) {
  yield put(setProductionState({ loading: true }));
  // @ts-ignore
  const productionData = yield call(() => addToolsProductionDataApi(payload?.requestBody));
  if (productionData) {
    yield put(addProductionDataState(productionData));
    triggerSuccessNotification('Data saved successfully');
    payload.resp(productionData);
  }

  yield put(setProductionState({ loading: false }));
}

function* EDIT_PRODUCTION_DATA({ payload }: any) {
  yield put(setProductionState({ loading: true }));
  // @ts-ignore
  const productionData = yield call(() =>
    editToolsProductionDataApi(payload?.requestBody, payload.productionId)
  );
  if (productionData) {
    yield put(
      updateProductionDataState({
        data: productionData,
        productionId: payload.productionId,
      })
    );

    payload.resp(productionData);
    triggerSuccessNotification('Data edited successfully');
  }

  yield put(setProductionState({ loading: false }));
}

function* DELETE_TOOLS_PRODUCTION_DATA({ payload }: any) {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this data?')) {
    // @ts-ignore
    const isProductionDeleted = yield call(() =>
      deleteToolsProductionDataApi(payload.productionId)
    );
    if (isProductionDeleted) {
      yield put(removeProductionDataState({ productionId: payload.productionId }));
      triggerSuccessNotification('Data deleted Successfully');
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(loadStepProductionData, LOAD_PRODUCTION_DATA),
    takeEvery(addToolsProductionData, ADD_PRODUCTION_DATA),
    takeEvery(editToolsProductionData, EDIT_PRODUCTION_DATA),
    takeEvery(deleteToolsProductionData, DELETE_TOOLS_PRODUCTION_DATA),
  ]);
}
