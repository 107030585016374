import { createSlice } from '@reduxjs/toolkit';

const orderStepSlice = createSlice({
  name: 'orderStep',
  initialState: { loading: false, data: [] },
  reducers: {
    setOrderStepState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setOrderStep: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadOrderStep = 'orderStep/LOAD_ORDERSTEP',
  setOrderStepState,
  setOrderStep,
}: any = orderStepSlice.actions;

export default orderStepSlice.reducer;
