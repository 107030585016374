import { createSlice } from '@reduxjs/toolkit';

const materialsSlice = createSlice({
  name: 'materials',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setMaterialsState: (state, action) => ({ ...state, ...action.payload }),
    setMaterialsData: (state, action) => {
      state.data = action.payload;
    },
    addMaterialsData: (state, action) => {
      state.data.unshift(action.payload);
    },
  },
});

export const {
  loadMaterials = 'materials/load',
  postMaterialsData = 'materials/add',
  setMaterialsState,
  setMaterialsData,
  addMaterialsData,
}: any = materialsSlice.actions;
export default materialsSlice.reducer;
