import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getCommentsApi } from '../../services/commentsService';
import { loadComments, setComments, setCommentsState } from '../reducers/commentsReducer';

function* LOAD_COMMENTS({ payload }: { payload: { id: number } }) {
  yield put(setCommentsState({ loading: true }));

  const comments: Record<string, never> = yield call(() => getCommentsApi(payload.id));

  if (comments) {
    yield put(setComments(comments));
  }

  yield put(setCommentsState({ loading: false }));
}

// function* UPDATE_COMMENTS({ payload }) {
//   yield put(setCommentsState({ loading: true }));

//   const comment = yield call(() => updateCommentsApi(payload.id, payload.data));

//   if (comment) {
//     yield put(updateCommentsData(comment));
//   }

//   yield put(setCommentsState({ loading: false }));
// }

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadComments, LOAD_COMMENTS),
    // takeEvery(updateComments, UPDATE_COMMENTS),
  ]);
}
