import chatServerClient from './axios/chatServerClient';

export async function getSingleThreadApi(userId: number, id: number): Promise<string> {
  return userId && id
    ? chatServerClient
        .get(`/users/${userId}/conversations/${id}/messages`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
