import { createSlice } from '@reduxjs/toolkit';

const generalInformationSlice = createSlice({
  name: 'generalInformation',
  initialState: { loading: false, data: [] },
  reducers: {
    setGeneralInformationState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setGeneralInformation: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadGeneralInformation = 'generalInformation/LOAD_GENERALINFORMATION',
  setGeneralInformationState,
  setGeneralInformation,
}: any = generalInformationSlice.actions;

export default generalInformationSlice.reducer;
