import apiClient from '../axios';

export async function getColorBackSideApi(order_id: number): Promise<string> {
  return apiClient
    .get(`/color_back_side/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

// export async function addChallanApi(
//   order_id: number,
//   data: FormData
// ): Promise<{ data: ChallanProps }> {
//   return apiClient.post(`/invoice/${order_id}`, data);
// }

// export async function deleteChallanApi(order_id: number): Promise<{ data: ChallanProps }> {
//   return apiClient.delete(`/invoice/${order_id}`);
// }
