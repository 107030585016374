import React from 'react';
import { Link } from 'react-router-dom';
import { basename } from '../../utils/constant';
import UserMenu from '../menu/UserMenu';

const MainHeader = (): JSX.Element => {
  return (
    <div className="page-main-header omd-header d-flex align-items-center row main_header_wrap">
      <div className="left-side flex-grow-1 col-sm-12 col-md-6 header_logo">
        <Link to="/">
          <img
            src={`${basename}/static/logo-long.png`}
            alt="merchantbay"
            style={{ height: 50, width: 'auto' }}
          />
        </Link>
      </div>

      <div className="right-side p-0 pull-right col-sm-12 col-md-6 header_right">
        <ul className="nav-menus d-flex mb-0">
          <UserMenu />
        </ul>
      </div>
      <script id="result-template" type="text/x-handlebars-template">
        <div className="ProfileCard u-cf">
          <div className="ProfileCard-avatar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-airplay m-0"
            >
              <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
              <polygon points="12 15 17 21 7 21 12 15"></polygon>
            </svg>
          </div>
          <div className="ProfileCard-details">
            <div className="ProfileCard-realName"></div>
          </div>
        </div>
      </script>
      <script id="empty-template" type="text/x-handlebars-template">
        <div className="EmptyMessage">
          Your search turned up 0 results. This most likely means the backend is down, yikes!
        </div>
      </script>
    </div>
  );
};

export default MainHeader;
