import React from 'react';
import { connect } from 'react-redux';
import { loadTasks } from '../../../store/reducers/tasksReducer';
import { TasksProps } from '../../../types/states';
import CalendarSection from '../../pages/calendarPage/CalendarSection';
import TaskListByDateGroup from '../../pages/calendarPage/TaskListByDateGroup';

type StateProps = {
  tasks: { data: TasksProps[] };
};

const mapStateToProps = ({ tasks }: StateProps) => ({ tasks });

interface ICalendarWrapperProps extends StateProps {
  dispatch: any;
}

const CalendarWrapper = ({ tasks, dispatch }: ICalendarWrapperProps) => {
  React.useEffect(() => {
    dispatch({ type: loadTasks });
  }, [dispatch]);

  return (
    <div className="py-30 calendarPage">
      <div className="row align-items-stretch">
        <div className="col-md-8">
          <CalendarSection taskData={tasks.data} />
        </div>
        <div className="col-md-4">
          <TaskListByDateGroup tasks={tasks.data} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CalendarWrapper);
