import { createSlice } from '@reduxjs/toolkit';

const threadsSlice = createSlice({
  name: 'threads',
  initialState: { loading: false, activeThread: {}, unseenThreads: [], data: [] } as any,
  reducers: {
    setThreadsState: (state, action) => ({ ...state, ...action.payload }),
    setThreads: (state, action) => ({ ...state, ...action.payload }),
    setUnseenThreadsCount: (state, action) => {
      state.unseenThreads = action.payload;
    },
    increaseUnseenThreadsCount: (state, action) => {
      const unseenThreads = state.unseenThreads;
      for (const key in unseenThreads) {
        if (unseenThreads[key].conversation_id === action.payload.conversation_id) {
          unseenThreads[key]['unseen_count'] = unseenThreads[key]['unseen_count'] + 1;
        }
      }
      state.unseenThreads = unseenThreads;
    },
    setActiveThread: (state, action) => {
      if (action.payload) {
        state.activeThread = state?.data?.filter(
          (item: { _id: number }) => item._id === action.payload
        )[0];
      } else {
        state.activeThread = {};
      }
    },
    addThreads: (state, action) => {
      state.data?.push(action.payload);
    },
    updateThreadsData: (state, action) => {
      state.data = state?.data?.map((item: { _id: number }) =>
        item._id === action.payload._id ? action.payload : item
      );
    },
  },
});

export const {
  loadThreads = 'threads/loadThreads',
  loadUnseenThreadsCount = 'threads/loadUnseenThreadsCount',
  addNewThread = 'threads/addNewThread',
  addUserToGroupThreads = 'threads/addUserToGroupThreads',
  removeUserFromGroupThreads = 'threads/removeUserFromGroupThreads',
  setThreadsState,
  setThreads,
  setActiveThread,
  setUnseenThreadsCount,
  increaseUnseenThreadsCount,
  addThreads,
  updateThreadsData,
}: any = threadsSlice.actions;

export default threadsSlice.reducer;
