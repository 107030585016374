import { CommentProps } from '../types/states';
import apiClient from './axios';

export async function getCommentsApi(id: number): Promise<{ count: number; data: never[] }> {
  if (!id) return { count: 0, data: [] };
  return apiClient
    .get(`/tasks/${id}/comments`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addCommentsApi(
  task_id: number,
  data: { comment: string }
): Promise<{ data: CommentProps }> {
  return apiClient.post(`/tasks/` + task_id + `/comments/`, data);
}

// export async function updateCommentsApi(id, data) {
//   return apiClient
//     .put(`/comments/${id}/`, data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch(() => {
//       return false;
//     });
// }

// export async function deleteCommentsApi(id, data) {
//   return apiClient
//     .delete(`/tasks/${id}/`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch(() => {
//       return false;
//     });
// }
