import { select, all, put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import {
  addThreadApi,
  addUserToGroupThreadsApi,
  getThreadsApi,
  getUnseenThreadsCount,
  removeUserFromGroupThreadsApi,
} from '../../services/threadsService';
import { getUserInfo } from '../../utils/getLocalStorgeData';
import { triggerErrorNotification } from '../../utils/notifications';
import { getActiveOrderId, getCompanyId } from '../../utils/orderUtils';
import { getThreadUsers } from '../../utils/threadUtils';
import {
  addNewThread,
  addThreads,
  addUserToGroupThreads,
  loadThreads,
  loadUnseenThreadsCount,
  removeUserFromGroupThreads,
  setThreads,
  setThreadsState,
  setUnseenThreadsCount,
  updateThreadsData,
} from '../reducers/threadsReducer';

function* LOAD_THREADS({ payload }: { payload: { id: number; orderId: number } }) {
  yield put(setThreadsState({ loading: true }));

  const threads: Record<string, never> = yield call(() =>
    getThreadsApi(payload.id, payload.orderId)
  );

  if (threads) {
    yield put(setThreads(threads));
  }

  yield put(setThreadsState({ loading: false }));
}

function* LOAD_UNSEEN_THREADS_COUNT({ payload }: Record<string, never>) {
  yield put(setThreadsState({ loading: true }));

  const unseenThreads: Record<string, never> = yield call(() => getUnseenThreadsCount(payload));
  if (unseenThreads) {
    yield put(setUnseenThreadsCount(unseenThreads.data));
  }

  yield put(setThreadsState({ loading: false }));
}

function* ADD_NEW_THREAD({
  payload,
}: {
  payload: { onAddThread: (thread: Record<string, never>) => void; id: number };
}) {
  yield put(setThreadsState({ loading: true }));
  // @ts-ignore
  let state = yield select();

  const threads: Record<string, never> = yield call(() =>
    getThreadsApi(state?.auth?.data?.user?.id, state?.order?.data.id)
  );
  // @ts-ignore
  state = yield select();

  if (threads) {
    yield put(setThreads(threads));
    if (!getThreadUsers('DM').includes(payload.id)) {
      const thread: Record<string, never> = yield call(() =>
        addThreadApi({
          creator: {
            id: getUserInfo().id,
            // name: getUserInfo().name,
            // avater:
            //   "https://pickaface.net/gallery/avatar/demo.webmaster54118e9c81966.png",
          },
          members: [
            {
              id: getUserInfo().id,
              // name: getUserInfo().name,
              // avater:
              //   "https://pickaface.net/gallery/avatar/demo.webmaster54118e9c81966.png",
            },
            {
              id: payload.id,
              // name: payload.name,
              // avater: payload.avater,
            },
          ],
          conversation_type: 'DM',
          company: getCompanyId(),
          order: getActiveOrderId(),
        })
      );
      if (thread) {
        yield put(addThreads(thread));
        payload?.onAddThread(thread);
      }
    } else {
      triggerErrorNotification('A thread with this user is already exist.');
      yield put(setThreadsState({ loading: false }));
    }
  }

  yield put(setThreadsState({ loading: false }));
}

function* ADD_USER_TO_GROUP_THREADS({
  payload,
}: {
  payload: { orderId: number; conversationId: string; data: Record<string, never> };
}) {
  yield put(setThreadsState({ loading: true }));
  const threads: Record<string, never> = yield call(() =>
    addUserToGroupThreadsApi(payload.orderId, payload.conversationId, payload.data)
  );

  if (threads) {
    yield put(updateThreadsData(threads.data));
  }

  yield put(setThreadsState({ loading: false }));
}

function* REMOVE_USER_TO_GROUP_THREADS({ payload }: { payload: { id: number; userId: number } }) {
  yield put(setThreadsState({ loading: true }));

  const threads: Record<string, never> = yield call(() =>
    removeUserFromGroupThreadsApi(payload.id, payload.userId)
  );

  if (threads) {
    yield put(updateThreadsData(threads.data));
  }

  yield put(setThreadsState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadThreads, LOAD_THREADS),
    takeEvery(loadUnseenThreadsCount, LOAD_UNSEEN_THREADS_COUNT),
    takeLatest(addNewThread, ADD_NEW_THREAD),
    takeLatest(addUserToGroupThreads, ADD_USER_TO_GROUP_THREADS),
    takeLatest(removeUserFromGroupThreads, REMOVE_USER_TO_GROUP_THREADS),
  ]);
}
