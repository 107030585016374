import { createSlice } from '@reduxjs/toolkit';
import { RFQMessageProps } from '../../types/states';

const rfqmessageSlice = createSlice({
  name: 'rfqmessage',
  initialState: { loading: false, data: [] } as { loading: boolean; data: RFQMessageProps[] },
  reducers: {
    setrfqmessageState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setrfqMessage: (state, action) => {
      state.data = [];
      state.data = action.payload?.data?.messages;
    },
    addrfqMessage: (state, action) => {
      state.data.push(action.payload?.data);
    },
  },
});

export const {
  loadrfqmessage = 'rfqmessage/loadrfqmessage',
  setrfqmessageState,
  setrfqMessage,
  addrfqMessage,
}: any = rfqmessageSlice.actions;

export default rfqmessageSlice.reducer;
