import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { updateTasks } from '../../../store/reducers/tasksReducer';
import { loadTodaysTasks } from '../../../store/reducers/todaysTasksReducer';
import { OrderProps, TasksProps } from '../../../types/states';
import { getUserById } from '../../../utils/userUtils';
import Button from '../button/Button';

type StateProps = { order: { data: OrderProps } };

const mapStateToProps = ({ order }: StateProps) => ({ order });

interface ITaskCountTableProps extends StateProps {
  name?: string;
  dispatch?: any;
  tasks: TasksProps[];
}

const TaskTable = ({ tasks, name = '', dispatch, order }: ITaskCountTableProps) => {
  const handleComplete = (task: TasksProps) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to ${
          !task.is_completed ? 'complete the task' : 'make the task due'
        }?`
      )
    ) {
      dispatch({
        type: updateTasks,
        payload: {
          id: task.id,
          data: { is_completed: !task.is_completed },
          completeFunc: () => {
            dispatch({
              type: loadTodaysTasks,
              payload: { id: order.data.id },
            });
          },
        },
      });
    }
  };

  return (
    <>
      <h3>{`${name} `}Tasks</h3>
      {tasks?.length > 0 ? (
        <table className="text-center">
          <thead>
            <tr>
              <th>Task Name</th>
              <th>Description</th>
              <th>Assigned Person</th>
              <th>Status</th>
              <th>Complete Status</th>
              <th>Due Date</th>
              <th>Due Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks?.map((task) => (
              <tr key={task.id}>
                <td>
                  <span
                    className="d-block mx-auto"
                    style={{
                      whiteSpace: 'nowrap',
                      width: 120,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {task.task_name}
                  </span>
                </td>
                <td>
                  <span
                    className="d-block mx-auto"
                    style={{
                      whiteSpace: 'nowrap',
                      width: 120,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {task.description}
                  </span>
                </td>
                <td>{getUserById(task.assigned_person)?.name}</td>
                <td>{task.task_status}</td>
                <td>{task.is_completed ? 'Complete' : 'Not Complete'}</td>
                <td>{moment(task.due_date).format('ll')}</td>
                <td>{moment(task.due_date).format('LT')}</td>
                <td className="py-1">
                  {task.is_completed ? (
                    <Button
                      size="sm"
                      color="primary"
                      className="px-12 ms-1"
                      icon
                      noShadow
                      onClick={() => handleComplete(task)}
                    >
                      <i className="fas fa-undo-alt"></i>
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="theme-primary"
                      className="px-12 ms-1"
                      icon
                      noShadow
                      onClick={() => handleComplete(task)}
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        'There are no tasks.'
      )}
    </>
  );
};

export default connect(mapStateToProps)(TaskTable);
