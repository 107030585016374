import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getCostSheetApi } from '../../services/costSheetService';
import { loadCostSheet, setCostSheetState, setCostSheet } from '../reducers/costSheetReducer';

function* LOAD_COSTSHEET({ payload }: { payload: { id: string } }) {
  yield put(setCostSheetState({ loading: true }));

  const costSheet: Record<string, never> = yield call(() => getCostSheetApi(payload.id));

  if (costSheet) {
    yield put(setCostSheet({ data: costSheet }));
  } else {
    yield put(setCostSheet({}));
  }

  yield put(setCostSheetState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadCostSheet, LOAD_COSTSHEET)]);
}
