import { ChallanProps, ItemSpecificationProps } from '../../types/states';
import apiClient from '../axios';

export async function getItemSpecificationApi(order_id: number): Promise<string> {
  return apiClient
    .get(`/item_specification/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
export async function addItemSpecificationApi(
  data: FormData
): Promise<{ data: ItemSpecificationProps }> {
  return apiClient.post(`/item_specification/`, data);
}
// export async function addChallanApi(
//   order_id: number,
//   data: FormData
// ): Promise<{ data: ChallanProps }> {
//   return apiClient.post(`/invoice/${order_id}`, data);
// }

// export async function deleteChallanApi(order_id: number): Promise<{ data: ChallanProps }> {
//   return apiClient.delete(`/invoice/${order_id}`);
// }
