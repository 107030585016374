import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getStepsApi } from '../../services/productionDataService';
import { loadSteps, setSteps, setStepsState } from '../reducers/stepsReducer';

function* LOAD_STEPS({ id }: { id: number }) {
  yield put(setStepsState({ loading: true }));

  const steps: Record<string, never> = yield call(() => getStepsApi());

  if (steps) {
    yield put(setSteps({ activeSteps: id, data: steps?.data }));
  }

  yield put(setStepsState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadSteps, LOAD_STEPS)]);
}
