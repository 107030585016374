import apiClient from './axios/storeClient';

export async function addMaterialsApi(data: Record<string, never>): Promise<string> {
  return apiClient
    .post('materials/bill', data)
    .then((resp) => resp.data)
    .catch(() => false);
}
export async function getMaterialsApi({
  buyer,
  style,
}: {
  buyer: string;
  style: string;
}): Promise<string> {
  return apiClient
    .get(`materials/bill/buyer/${buyer}/style/${style}`)
    .then((resp) => resp.data)
    .catch(() => false);
}
