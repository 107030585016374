import { all, call, put, takeEvery } from 'redux-saga/effects';
import { addMaterialsApi, getMaterialsApi } from '../../services/materialService';
import { triggerSuccessNotification } from '../../utils/notifications';
import {
  addMaterialsData,
  loadMaterials,
  postMaterialsData,
  setMaterialsData,
  setMaterialsState,
} from '../reducers/materialsReducer';

function* LOAD_MATERIALS({ payload }: Record<string, any>): Record<string, any> {
  yield put(setMaterialsState({ loading: true }));
  const materialsData = yield call(() => getMaterialsApi(payload));
  if (materialsData) {
    yield put(setMaterialsData(materialsData));
  }
  yield put(setMaterialsState({ loading: false }));
}
function* POST_MATERIAL_DATA({ payload, callback }: Record<string, any>): Record<string, any> {
  yield put(setMaterialsState({ loading: true }));
  const materialData = yield call(() => addMaterialsApi(payload));
  if (materialData) {
    yield put(addMaterialsData(materialData));
    triggerSuccessNotification('Data inserted successfully.');
    callback(materialData);
  }
  yield put(setMaterialsState({ loading: false }));
}

export default function* rootSaga(): Record<string, any> {
  yield all([
    takeEvery(loadMaterials, LOAD_MATERIALS),
    takeEvery(postMaterialsData, POST_MATERIAL_DATA),
  ]);
}
