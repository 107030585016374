// Main interceptor service

import axios from 'axios';
import { logout } from '../../utils/authUtils';

const apiClient = axios.create({
  // baseURL: 'http://13.229.207.78:9999/api/' + 'omd/',

  baseURL: process.env.REACT_APP_OMD_API_URL + 'omd/',
});

apiClient.interceptors.request.use((request) => {
  // request.headers.AccessToken = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMxMjY3NDE3LCJqdGkiOiJhMWM1NDRjNzk3N2Y0ODQ4ODJmYzM1ZGM1ZTJkYTQwMyIsInVzZXJfaWQiOiI5MmYwNDdmYy1mOWZlLTQwYTQtOGYyYy0wNGMzYmFjNDQ3NjQifQ.jpLBuFLzCedW3taj_vV8jMTTHALI5Xx74OP1CZ8XO5ofLcLqbWSay7Fpt7pAroBNDcrtxaSh_Ll-3yo8KDJPVhlCHxktEF0x5zjv12uVbeLxPVsnFo3oG6uJuspk49cmGhH0VZoWpHryr1s9pK3xAae5Rzota2co9LXNpgU22KMI35mcFtoQLDLLZOC93kqtAsRx64J64D3YhGQI4FIJoHSzsyJQqCYu78b3ZxH2Zxib7HEJwPpk5Mww6cv8mfp1HMkncAFLVaGk900mqESu1MOIjGSTOw6qLUjQpApHMP5mPrcb-ZDBJbJjxoivSKLohn2uBdXE9Z2Q03ws2-bkA9BV98qBvNtKNJQIs24qGORC3ahdZ1DrvCJyk8rWPfVuNL6vOKrzU3L0Akkl5ng85wyKwK2G21Y6t8UkMKKjfpbzVX9120SlzpPYJJsum7NA79slwIVA8gUFXWMQN4zfAlwOkmnm4kHUUchmrUosJiFOvaltSDc76fr2mK3fXCaeoGjGSdT4sDyykczeIf3jDFxQfxsMexzlGBDG5cybX_b9eCUTmB7LXBdNfp38Ua10TUCumDoEWfkVWU0acS9HZOFf0IHxYkHmn5FTNW8cvdMiMeRwg1GJridiaNuuy5tArSRBWDSAyvp-Orof98RNce8kecW3YRQOLBrmh3MnmxI`;
  const userString = localStorage.getItem('user') || '';
  const user = JSON.parse(userString);
  request.headers.Authorization = 'Bearer ' + user.access;
  request.headers.Authorization =
    'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYwODE0NzQ2LCJqdGkiOiIzYjZmN2I4OWEyMTc0ZGQ2OWI5MzMwYTg5MDI5YWQ1OSIsInVzZXJfaWQiOjUxMDcsImNvbXBhbnlfaWQiOjg4MSwiaXNfYWRtaW4iOnRydWUsInVzZXJfbmFtZSI6IkRlbW8gVXNlciIsInVzZXJfdHlwZSI6InN1cHBsaWVyIiwiZW1haWwiOiJkZW1vQG1lcmNoYW50YmF5LmNvbSIsInBob25lIjoiODgwMTcxMjMxMjMxMiIsInVzZXJfcGljdHVyZSI6IiIsImNvbXBhbnkiOiJCcmlnZ3MgQXJub2xkIENvbSJ9.YX1QCKaSQg8dhGR_mWbBBhaMuNDh0Xt4NmO0P9erlZyHylaQw48PheL5DwLlkwsy9ZotR-bwWodMvTWmU9fSkx2_6X7sss6zkF2MgZCy5OMbFvAHck2udtzkoAwlIOkEgBvLz9rIsnXvdgn1z3dcgNMBPnnlznkUPaOFWNeSUjvenk97tB55CLmAH1FHT7OlzXMPxq-X5_Q9gqu4bTZxkPQhZdr40a_8_miS-Xyajjb-wgpSoPOTKSyYcBoXXkgnyQ_M1AP88-YVhscrNGjPTHyCKqGuED1OxVk34LTdvFDJaaQ4BxRVkjteSmbnpau23_E-kcowEjnQveLL2N_gqjd-zAJzWGqDOAGIWu4LfWnYpc85yr7mp_i2mL4kLojNTpY9rbyKZCuNsVpvx2l3lXcEYr-WoWPPmEQaFAZPptFXPHSztmT7N3GNMp2mMmzEMNbc0Zi-SvEhVMWKQ3agqfqAbdvfkwY_LXdoLE74z5DFhcBJcTjG2pRczVkTXsrCKQzWremPF4KssAQaVPbdqjuquQjFX8kBPgxLaWiqj8ae1mIbBJruQMI9vAQEQ0UmGuFGyTybRWxM6v2t7-HRuvoCeCgoM5_gVEL6Lz09j1ZiB4WOXqywmrOL3kFYUbTXgCbpx1jLc-Xf6bXpo6mwgDBelSLytW4v3lbZ63qJP_A';

  return request;
});

apiClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      logout();
    }
  }
);

export default apiClient;
