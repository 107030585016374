import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getProductionApi } from '../../../services/productionService';
import { loadProduction, setProduction, setProductionState } from '../reducer/productionReducer';

function* LOAD_PRODUCTION({ payload }: { payload: { orderId: number } }) {
  //console.log(payload);
  yield put(setProductionState({ loading: true }));

  const challan: Record<string, never> = yield call(() => getProductionApi(payload.orderId));
  //console.log(challan);
  if (challan) {
    yield put(setProduction(challan));
  }

  yield put(setProductionState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadProduction, LOAD_PRODUCTION)]);
}
