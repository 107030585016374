import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getMessageApi } from '../../services/messageService';
import { ContactProps } from '../../types/states';
import { loadMessage, setMessage, setMessageState } from '../reducers/messageReducer';

function* LOAD_MESSAGE({ payload }: { payload: ContactProps }) {
  yield put(setMessageState({ loading: true }));
  const message: Record<string, never> = yield call(() => getMessageApi(payload));
  if (message) {
    // //console.log(message);
    yield put(setMessage(message));
  }

  yield put(setMessageState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadMessage, LOAD_MESSAGE)]);
}
