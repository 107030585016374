import { createSlice } from '@reduxjs/toolkit';

const productionDataSlice = createSlice({
  name: 'productionData',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setProductionDataState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setProductionData: (state, action) => ({ ...state, ...action.payload }),
    updateProductionData: (state, action) => {
      state.data = state.data.map((item: { id: number }) =>
        item.id === action.payload.id ? action.payload : item
      );
      // state.data.data.unshift(action.payload);
    },
    addProductionData: (state, action) => {
      state.data = state.data.map((item: { id: number; files: Record<string, never>[] }) =>
        item.id === action.payload.id
          ? { ...item, files: [...item.files, action.payload.file] }
          : item
      );
    },
    addProductionDataFile: (state, action) => {
      state.data.unshift(action.payload);
    },
    removeProductionData: (state, action) => {
      state.data = state.data.filter((item: { id: number }) => item.id !== action.payload);
    },
    removeProductionDataFile: (state, { payload }) => {
      state.data = state.data.map((item: { id: number; files: Record<string, any> }) => {
        if (item.id === payload.item.id) {
          return {
            ...item,
            files: item.files.filter((data: { id: number }) => data.id !== payload.id),
          };
        }
        return item;
      });
    },
  },
});

export const {
  loadProductionData = 'productionData/LOAD_PRODUCTION_DATA',
  deleteProductionData = 'productionData/DELETE_PRODUCTION_DATA',
  updateProductionData,
  removeProductionData,
  setProductionDataState,
  setProductionData,
  addProductionData,
  addProductionDataFile,
  removeProductionDataFile,
}: any = productionDataSlice.actions;

export default productionDataSlice.reducer;
