import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import MainLayout from './MainLayout';
import InitialConfigLayout from './InitialConfigLayout';
import { ToastContainer } from 'react-toastify';

type RootLayoutProps = {
  location?: Location;
  socket: any;
  children?: React.ReactNode;
};
const RootLayout = ({ children, location, socket }: RootLayoutProps) => {
  if (location?.pathname.includes('/404')) {
    return children;
  }
  if (location?.pathname.includes('/login')) {
    return children;
  }
  if (!location?.pathname.includes('/initialConfiguration')) {
    return (
      <>
        <ToastContainer />

        {/* @ts-ignore */}
        <MainLayout socket={socket}>{children}</MainLayout>
      </>
    );
  }
  return (
    <>
      {/* <ToastContainer /> */}
      {/* <InitialConfigLayout>{children}</InitialConfigLayout> */}
    </>
  );
};
// @ts-ignore
export default withRouter(RootLayout);
