import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getMainSpecificationApi } from '../../../services/trims/mainSpecificationService';
import {
  loadMainSpecification,
  setMainSpecification,
  setMainSpecificationState,
} from '../reducer/mainSpecificationReducer';

function* LOAD_MAINSPECIFICATION({ payload }: { payload: { orderId: any } }) {
  //console.log('Main Specification Order_Id', payload.orderId);
  yield put(setMainSpecificationState({ loading: true }));

  const mainSpecification: Record<string, never> = yield call(() =>
    getMainSpecificationApi(payload.orderId)
  );
  if (mainSpecification) {
    yield put(setMainSpecification(mainSpecification));
  }

  yield put(setMainSpecificationState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadMainSpecification, LOAD_MAINSPECIFICATION)]);
}
