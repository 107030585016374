import { createSlice } from '@reduxjs/toolkit';

const todaysTasksSlice = createSlice({
  name: 'todaysTasks',
  initialState: { loading: false, data: [] },
  reducers: {
    setTodaysTasksState: (state, action) => ({ ...state, ...action.payload }),
    setTodaysTasks: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  loadTodaysTasks = 'todaysTasks/LOAD_TODAYS_TASKS',
  setTodaysTasksState,
  setTodaysTasks,
}: any = todaysTasksSlice.actions;

export default todaysTasksSlice.reducer;
