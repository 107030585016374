import apiClient from './axios';

export async function getTodaysTasksApi(id: number): Promise<string> {
  return id
    ? apiClient
        .get(`/orders/${id}/tasks/filter/today`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
