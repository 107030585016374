import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getChallanApi } from '../../../services/trims/challanService';
import { loadChallan, setChallanState, setChallan } from '../../trims/reducer/challanRuducer';

function* LOAD_CHALLAN({ payload }: { payload: { orderId: number } }) {
  //console.log(payload);
  yield put(setChallanState({ loading: true }));

  const challan: Record<string, never> = yield call(() => getChallanApi(payload.orderId));
  if (challan) {
    yield put(setChallan(challan));
  }

  yield put(setChallanState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadChallan, LOAD_CHALLAN)]);
}
