import { createSlice } from '@reduxjs/toolkit';

const tasksStatusCountSlice = createSlice({
  name: 'tasksStatusCount',
  initialState: { loading: false, data: [] },
  reducers: {
    setTasksStatusCountState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setTasksStatusCount: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  loadTasksStatusCount = 'tasksStatusCount/LOAD_TASKS_STATUS_COUNT',
  updateTasksStatusCount = 'tasksStatusCount/UPDATE_TASKS_STATUS_COUNT',
  setTasksStatusCountState,
  setTasksStatusCount,
}: any = tasksStatusCountSlice.actions;

export default tasksStatusCountSlice.reducer;
