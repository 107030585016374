import { AuthProps, ContactProps, RFQMessageProps, RFQProps } from '../types/states';
import chatServerClient from './axios/chatServerClient';
import rfqClient from './axios/rfqClient';

export async function getrfqMessageApi(payload: {
  rfq_id: string;
  user_id: number;
}): Promise<string> {
  const url = 'messages/' + payload?.rfq_id + '/user/' + payload?.user_id;

  return url
    ? rfqClient
        .get(url)
        .then((response: any) => {
          return response.data;
        })
        .catch((error) => {
          return false;
        })
    : false;
}

export async function sendFileMessageApi(data: Record<string, any>): Promise<string> {
  return chatServerClient
    .post(`/messages`, data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
