import { createSlice } from '@reduxjs/toolkit';
import { DashboardProps } from '../../types/states';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: { loading: false, data: [] } as { loading: boolean; data: DashboardProps[] },
  reducers: {
    setDashboardState: (state: any, action: any) => ({ ...state, ...action.payload }),
    setDashboard: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  loadDashboard = 'dashboard/load',
  setDashboardState,
  setDashboard,
}: any = dashboardSlice.actions;

export default dashboardSlice.reducer;
