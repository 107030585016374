import store from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../store/reducers/authReducer';
export function isTheUserAdmin(): boolean | undefined {
  const mainStore = store.getState();
  const auth: { user?: { is_admin: boolean } } = mainStore?.auth?.data;

  return auth?.user?.is_admin;
}

export const logout = (): void => {
  localStorage.removeItem('user');
  window.location.replace('/login');
};

export const userData = (): any => {
  // @ts-ignore
  const autData = JSON.parse(localStorage.getItem('user'));
  return autData?.user;
};
