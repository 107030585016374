import { createSlice } from '@reduxjs/toolkit';
import { MessageProps } from '../../types/states';

const messageSlice = createSlice({
  name: 'message',
  initialState: { loading: false, data: [] } as { loading: boolean; data: MessageProps[] },
  reducers: {
    setMessageState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setMessage: (state, action) => {
      state.data = [];
      state.data = action.payload?.data?.messages;
    },
    addMessage: (state, action) => {
      state.data.push(action.payload?.data);
    },
  },
});

export const {
  loadMessage = 'message/loadMessage',
  setMessageState,
  setMessage,
  addMessage,
}: any = messageSlice.actions;

export default messageSlice.reducer;
