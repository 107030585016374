import { createSlice } from '@reduxjs/toolkit';
import { OrderProps } from '../../types/states';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: { loading: false, data: [] } as { loading: boolean; data: OrderProps[] },
  reducers: {
    setOrdersState: (state, action) => ({ ...state, ...action.payload }),
    setOrders: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { loadOrders = 'orders/load', setOrdersState, setOrders }: any = ordersSlice.actions;

export default ordersSlice.reducer;
