import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getBTBApi } from '../../services/btbService';
import { loadBTB, setBTBState, setBTB } from '../reducers/btbReducer';

function* LOAD_BTB({ payload }: { payload: { id: number } }) {
  yield put(setBTBState({ loading: true }));

  const btb: Record<string, never> = yield call(() => getBTBApi(payload.id));
  if (btb) {
    yield put(setBTB(btb));
  }

  yield put(setBTBState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadBTB, LOAD_BTB)]);
}
