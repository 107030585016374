import React from 'react';
import {
  Bell,
  // BookOpen,
  Edit3,
  LogOut,
  Settings,
  User,
  Users,
} from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconBadge from '../common/badge/IconBadge';
import Button from '../common/button/Button';
import Notification from '../common/notification/Notification';
import Modal from '../common/modal/Modal';
import CalendarWrapper from '../common/wrapper/CalendarWrapper';
import { isTheUserAdmin, logout } from '../../utils/authUtils';
import { AuthProps, NotificationProps, UsersProps } from '../../types/states';
import { basename } from '../../utils/constant';
import { loadNotification } from '../../store/reducers/notificationReducer';
import { setAuth } from '../../store/reducers/authReducer';
import Avatar from 'react-avatar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { updateOrderCache } from '../../services/orderService';
import { updateBillCache } from '../../services/trims/accountService';
import { updateChallanCache } from '../../services/trims/challanService';
type StateProps = {
  auth: { data: AuthProps };
  users: { data: UsersProps[] };
  notification: { data: NotificationProps[]; unseenNotification: { count: number } };
};
interface IUserMenu extends StateProps {
  dispatch: any;
}

const mapStateToProps = ({ auth, users, notification }: StateProps) => ({
  auth,
  users,
  notification,
});

const UserMenu = ({ dispatch, auth, users, notification }: IUserMenu) => {
  // const [isShowNotification, setIsShowNotification] = React.useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = React.useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [isCalendarSection, setIsCalendarSection] = React.useState(true);
  const [isTaskSelected, setIsTaskSelected] = React.useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    data: { user },
  } = auth;

  const filteredUser = (Array.isArray(users?.data) &&
    users?.data?.filter((item) => item?.id === user?.id)[0]) || {
    user_picture: `${basename}/static/user/user.png`,
  };

  return (
    <>
      {' '}
      <li className="userMenu d-flex align-content-center">
        <div className="align-items-center d-inline-flex gap-1">
          {auth?.data?.user?.is_admin == true && (
            <button
              style={{
                background: '#368296',
                margin: 'auto',
                padding: '5px 10px',
                borderRadius: '5px',
                border: 'none',
                color: 'white',
              }}
              onClick={() => {
                updateOrderCache();
                updateChallanCache();
                updateBillCache();
              }}
            >
              Sync
            </button>
          )}{' '}
          <div className="user-details text-left">
            <p className="user-name mb-1 me-4 d-flex align-items-center">{user?.name}</p>
            <p className="user-job-title mb-2 me-4">
              {user?.is_admin ? 'Administrator' : user?.user_type}
            </p>
            {/* <Button
              size="sm"
              color="theme-primary"
              className="d-inline-block"
              onClick={() => {
                setIsCalendarModalOpen(true);
                setTimeout(() => {
                  setIsCalendarOpen(true);
                }, 500);
              }}
            >
              T&A Management
            </Button> */}
          </div>
          {/* <div className="notificationBell px-2 d-flex align-items-end align-self-stretch dropdown"> */}
          {/* <span
              onClick={() =>
                dispatch({
                  type: loadNotification,
                  payload: { id: user?.id },
                })
              }
              className="dropdown-toggle"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              role="button"
            >
              <IconBadge count={notification?.unseenNotification?.count}>
                <Bell />
              </IconBadge>
            </span> */}
          {/* <div className="dropdown-menu border-0" aria-labelledby="dropdownMenuButton1">
              <Notification />
            </div>
          </div> */}
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{ backgroundColor: 'white', border: 'none', padding: '0px' }}
              >
                <Avatar
                  size="60"
                  facebook-id="invalidfacebookusername"
                  src={
                    filteredUser?.user_picture
                      ? filteredUser?.user_picture
                      : `${basename}/static/user/user.png`
                  }
                  round={true}
                  alt="header-user"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {auth?.data?.user?.is_admin && (
                  <Dropdown.Item className={`${!isTheUserAdmin() ? 'pb-10' : ''}`}>
                    <Link to={`${basename}/profile`} style={{ color: 'black' }}>
                      <User /> Edit Profile
                    </Link>
                  </Dropdown.Item>
                )}

                {auth?.data?.user?.is_admin && (
                  <Dropdown.Item className="pb-10">
                    <Link to={`${basename}/settings`} style={{ color: 'black' }}>
                      <Settings /> Settings
                    </Link>
                  </Dropdown.Item>
                )}

                {auth?.data?.user?.is_admin && (
                  <Dropdown.Item>
                    <Link to={`${basename}/users`} style={{ color: 'black' }}>
                      <Users /> User Management
                    </Link>
                  </Dropdown.Item>
                )}

                <hr className="my-1" />

                {auth?.data?.user?.is_admin && (
                  <Dropdown.Item
                    onClick={() =>
                      window.location.assign(`${process.env.REACT_APP_SSO_BASE_URL}passwordreset`)
                    }
                  >
                    {/* <Link to={}> */}
                    <Edit3 /> Change Password
                    {/* </Link> */}
                  </Dropdown.Item>
                )}

                <Dropdown.Item>
                  <Link
                    to="/"
                    onClick={() => {
                      logout();
                      dispatch(setAuth(''));
                    }}
                    style={{ color: 'black' }}
                  >
                    <LogOut /> Log out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <span className="img-80 onhover-dropdown">
            <span className=" object-fit-cover rounded-circle blur-up lazyloaded overflow-hidden h-100 w-100 d-block">
              {/* img */}
              {/* <img
                className="h-100 w-100 object-fit-cover"
                src={
                  filteredUser?.user_picture
                    ? filteredUser?.user_picture
                    : `${basename}/static/user/user.png`
                }
                alt="header-user"
              /> */}
            </span>

            {/* <ul
              className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"
              style={{ width: 200 }}
            >
              {auth?.data?.user?.is_admin && (
                <li className={`${!isTheUserAdmin() ? 'pb-10' : ''}`}>
                  <Link to={`${basename}/profile`}>
                    <User /> Edit Profile
                  </Link>
                </li>
              )}
              {auth?.data?.user?.is_admin && (
                <li className="pb-10">
                  <Link to={`${basename}/settings`}>
                    <Settings /> Settings
                  </Link>
                </li>
              )}
              {auth?.data?.user?.is_admin && (
                <li>
                  <Link to={`${basename}/users`}>
                    <Users /> User Management
                  </Link>
                </li>
              )}

              <hr className="my-0" />

              {auth?.data?.user?.is_admin && (
                <li
                  onClick={() =>
                    window.location.assign(`${process.env.REACT_APP_SSO_BASE_URL}passwordreset`)
                  }
                >
                  
                  <Edit3 /> Change Password
                  
                </li>
              )}
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    logout();
                    dispatch(setAuth(''));
                  }}
                >
                  <LogOut /> Log out
                </Link>
              </li>
            </ul> */}
          </span>
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
      </li>
      <Modal
        visible={isCalendarModalOpen}
        onClose={() => {
          setIsCalendarModalOpen(false);
          setIsCalendarOpen(false);
          setIsCalendarSection(true);
        }}
        resetOnLoad
        customStyles={{
          width: isCalendarSection ? 'calc(100% - 30px)' : 1200,
          overflow: 'auto',
          height: 'calc(100vh - 30px)',
          transition: '.3s',
        }}
      >
        <div className="d-flex justify-content-between">
          <h4 className={isCalendarSection ? 'me-4' : ''}>{isCalendarSection ? 'Calendar' : ''}</h4>
          <Button
            color="theme-primary"
            size="sm"
            noShadow
            onClick={() => {
              setIsCalendarSection(!isCalendarSection);
            }}
            className="me-5"
          >
            {isCalendarSection ? 'Add T&A' : 'Back to calendar'}
          </Button>
        </div>
        {isCalendarSection ? (
          isCalendarOpen && <CalendarWrapper />
        ) : (
          <div style={{ height: 'calc(100% - 32px)' }}>
            {isTaskSelected ? (
              <>
                <i
                  className={`fas fa-arrow-left text-green cursor-pointer`}
                  onClick={() => {
                    setIsTaskSelected(false);
                  }}
                ></i>

                {/* <TaskCommentCard /> */}
              </>
            ) : (
              <>
                {/* <h3 className="mb-4">Add New Task in {order?.data?.order_number}</h3> */}
                {/* <TimeAndActionForm singleInput setIsTaskSelected={setIsTaskSelected} /> */}
              </>
            )}
          </div>
        )}
      </Modal>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you have chosen. Like,
          text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      {/* <Rodal
        showMask={true}
        height={500}
        width={400}
        visible={passwordModal}
        onClose={() => setPasswordModal(false)}
      >
        <EditMyPassword />
      </Rodal> */}
    </>
  );
};

export default connect(mapStateToProps)(UserMenu);
