import Pako from 'pako';
import {
  triggerErrorNotification,
  triggerSuccessNotification,
  triggerWarningNotification,
} from '../utils/notifications';
import apiClient from './axios';
import { da } from 'date-fns/locale';

export async function getOrdersApi(): Promise<string> {
  const endpoint = '/orders/order_status/all/';

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

const loadData = async () => {
  try {
    let data: any = [];
    return fetch(`${process.env.REACT_APP_BASE_URL}/uploads/orderlist.json.gzip`, {
      mode: 'cors',
    })
      .then(async (res) => {
        const buf = await res.arrayBuffer();
        const outBuf = Pako.inflate(buf);
        const str = new TextDecoder().decode(outBuf);
        data = JSON.parse(str);
        // setChallanDataList(data);
        // setAllChallanDataList(data);
        return data;
      })
      .catch((e) => {
        console.log();
      });
  } catch (err) {
    console.error('unable to decompress', err);
  }
};

export const updateOrderCache = async () => {
  const endpoint = `/orders/order_status/all/cache/false`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      // triggerSuccessNotification('Order Data Synced');
      return response.data;
    })
    .catch(() => {
      triggerErrorNotification('Try again Later');
      return false;
    });
};
export async function cacheOrdeList(cache: string): Promise<string> {
  const endpoint = `/orders/order_status/all/cache/${cache}`;

  const data = await loadData();
  // if (cache == 'true') {
  return data;
  // }
}

export async function getOrdersByFilterApi(filters: {
  status: string;
  search: string;
  step: string;
  cache: string;
}): Promise<string> {
  let endpoint = `/orders/order_status/${filters.status}`;
  if (filters.search) {
    endpoint += `/search/${filters.search}`;
  } else if (filters.step) {
    endpoint += `/step/${filters.step}`;
  } else if (filters.cache) {
    endpoint += `/cache/true`;
  } else {
    endpoint += '/';
  }

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getOrderApi(id: number): Promise<string> {
  const endpoint = `/orders/${id}`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
export async function updateOrderSheetAPI(data: any, order_id: number): Promise<string> {
  const endpoint = `/update_order_sheet/${order_id}`;

  return apiClient
    .post(endpoint, data)
    .then((response) => {
      //console.log('Sheet Up S: ', response);
      if (response['data']['code'] == 200) {
        triggerSuccessNotification(response['data']['message']);
      }
      if (response['data']['code'] == 400) {
        triggerWarningNotification(response['data']['message']);
      }
      return response.data;
    })
    .catch((e) => {
      //console.log('Sheet Up E: ', e);
      triggerErrorNotification('Please check your product code');
      return false;
    });
}

export async function updateOrderApi(id: number, data: Record<string, any>): Promise<string> {
  const endpoint = `/orders/${id}/`;

  return apiClient.put(endpoint, data);
}

export async function addOrderApi(data: Record<string, any>): Promise<any> {
  const endpoint = `/orders/`;

  return apiClient.post(endpoint, data);
}

export async function addUserApi(data: Record<string, never>): Promise<string> {
  const endpoint = `/adduser/`;
  return apiClient.post(endpoint, data);
}
