import React, { InputHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

//Shadow: md
//Size: sm, md
//Variant: outlined

interface ITextInputProps extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  placeholder?: string;
  shadow?: 'none' | 'md';
  size?: 'sm' | 'md' | 'lg' | any;
  className?: string;
  register?: UseFormRegisterReturn;
  onChange?: any;
  variant?: 'outlined' | '';
  rows?: number;
}

const TextInput = React.forwardRef(
  (
    {
      placeholder,
      shadow = 'md',
      size = 'md',
      className,
      register,
      onChange,
      variant = '',
      autoFocus,
      ...props
    }: ITextInputProps,
    ref: React.Ref<HTMLInputElement> | any
  ) => {
    return (
      <input
        className={`form-control textInput shadow-${shadow} size-${size} ${className} variant-${variant} px-4`}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        ref={ref}
        {...props}
        {...register}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
