import { AxiosResponse } from 'axios';
import apiClient from './axios';

export async function getTasksByOrderApi(id: number): Promise<string> {
  return id
    ? apiClient
        .get(`/orders/${id}/tasks/`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function updateKanbanApi(
  id: number,
  data: Record<string, never>
): Promise<boolean | AxiosResponse<any>> {
  return id
    ? apiClient
        .put(`/orders/${id}/kanban/`, { kanban: data })
        .then((response) => {
          return response;
        })
        .catch(() => {
          return false;
        })
    : false;
}
