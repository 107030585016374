// import { createStore, applyMiddleware, compose } from "redux";
// import thunkMiddleware from "redux-thunk";
// import logger from "redux-logger";
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { configureStore } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();
// const middleWares = [sagaMiddleware, thunkMiddleware, logger];

// function saveToLocalStorage(state) {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("state", serializedState);
//   } catch (e) {
//     return undefined;
//   }
// }

// function loadFromLocalStorage() {
//     try {
//         const serializedState = localStorage.getItem('state')
//         if(serializedState === null) return undefined
//         return JSON.parse(serializedState)
//     }catch (e) {
//         return undefined
//     }
// }

/**
 * Create a Redux store that holds the app state.
 */
const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware],
  // compose(
  //   applyMiddleware(...middleWares),

  //   //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
  //   window.devToolsExtension
  //     ? window.devToolsExtension()
  //     : function (f) {
  //         return f;
  //       }
  // )
  // devTools: false,
});

sagaMiddleware.run(sagas);

// const unsubscribe = store.subscribe(() => {
//     const state = store.getState();
//     saveToLocalStorage(state);
// });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
