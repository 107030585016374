import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getSingleThreadApi } from '../../services/singleThreadService';
import {
  loadSingleThread,
  setSingleThread,
  setSingleThreadState,
} from '../reducers/singleThreadReducer';

function* LOAD_SINGLE_THREAD({ payload }: { payload: { userId: number; id: number } }) {
  yield put(setSingleThreadState({ loading: true }));

  const singleThread: Record<string, never> = yield call(() =>
    getSingleThreadApi(payload.userId, payload.id)
  );

  if (singleThread) {
    yield put(setSingleThread(singleThread));
  }

  yield put(setSingleThreadState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadSingleThread, LOAD_SINGLE_THREAD)]);
}
