import { createSlice } from '@reduxjs/toolkit';

const optionSlice = createSlice({
  name: 'options',
  initialState: {
    loading: false,
    data: ['all', 'all', 'all', 'all'],
  },
  reducers: {
    setOptionsState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setOptions: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  loadOptions = 'options/LOAD_Options',
  setOptions,
  setOptionsState,
}: any = optionSlice.actions;

export default optionSlice.reducer;
