import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getAccessoriesApi, deleteAccessoriesApi } from '../../services/accessoriesService';
import { triggerSuccessNotification, triggerErrorNotification } from '../../utils/notifications';
import {
  loadAccessories,
  setAccessoriesState,
  setAccessories,
  deleteAccessories,
  removeAccessories,
} from '../reducers/accessoriesReducer';

function* LOAD_ACCESSORIES({ payload }: { payload: { id: number } }) {
  yield put(setAccessoriesState({ loading: true }));

  const accessories: Record<string, never> = yield call(() => getAccessoriesApi(payload.id));

  if (accessories) {
    yield put(setAccessories(accessories));
  } else {
    yield put(setAccessories({}));
  }

  yield put(setAccessoriesState({ loading: false }));
}

function* DELETE_ACCESSORIES({ payload }: { payload: { orderId: number; accId: number } }) {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this?')) {
    const accessories: Record<string, never> = yield call(() =>
      deleteAccessoriesApi(payload.orderId, payload.accId)
    );
    if (accessories) {
      yield put(removeAccessories({ id: accessories.id }));
      triggerSuccessNotification('Successfully Deleted');
    } else {
      triggerErrorNotification('Failed to Delete');
    }
  }
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadAccessories, LOAD_ACCESSORIES),
    takeEvery(deleteAccessories, DELETE_ACCESSORIES),
  ]);
}
