import { createSlice } from '@reduxjs/toolkit';
import { UsersByOrderProps } from '../../types/states';

const usersByOrderSlice = createSlice({
  name: 'usersByOrder',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    setUsersByOrderState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setUsersByOrder: (state, action) => ({ ...state, data: action.payload }),
    removeUsersByOrder: (state, action) => {
      state.data = state.data.filter((item: UsersByOrderProps) => item.user_id !== action.payload);
    },
  },
});

export const {
  loadUsersByOrder = 'usersByOrder/LOAD_USERS_BY_ORDER',
  setUsersByOrder,
  setUsersByOrderState,
  removeUsersByOrder,
}: any = usersByOrderSlice.actions;

export default usersByOrderSlice.reducer;
