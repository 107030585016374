import { createSlice } from '@reduxjs/toolkit';

const stepsSlice = createSlice({
  name: 'steps',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    setStepsState: (state, action) => ({ ...state, ...action.payload }),
    setSteps: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { loadSteps = 'steps/LOAD_Step', setSteps, setStepsState }: any = stepsSlice.actions;

export default stepsSlice.reducer;
