import { createSlice } from '@reduxjs/toolkit';

const qualityControlDataSlice = createSlice({
  name: 'qualityControlData',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setQualityControlDataState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setQualityControlData: (state, action) => ({ ...state, ...action.payload }),
    addQualityControlData: (state, action) => {
      state.data.unshift(action.payload);
    },
    updateQualityControlData: (state, action) => {
      state.data = state.data.map((item: { id: number }) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    removeQualityControlData: (state, action) => {
      state.data = state.data.filter((item: { id: number }) => item.id !== action.payload);
    },
    removeQualityControlDataFile: (state, { payload }) => {
      state.data = state.data.map((item: { id: number; files: any }) => {
        if (item.id === payload.item.id) {
          return {
            ...item,
            files: item.files.filter((data: { id: number }) => data.id !== payload.id),
          };
        }
        return item;
      });
    },
  },
});

export const {
  loadQualityControlData = 'qualityControlData/LOAD_QUALITY_CONTROL_DATA',
  deleteQualityControlData = 'qualityControlData/DELETE_QUALITY_CONTROL_DATA',
  setQualityControlDataState,
  setQualityControlData,
  addQualityControlData,
  updateQualityControlData,
  removeQualityControlData,
  removeQualityControlDataFile,
}: any = qualityControlDataSlice.actions;

export default qualityControlDataSlice.reducer;
