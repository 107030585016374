import { createSlice } from '@reduxjs/toolkit';

const qcSlice = createSlice({
  name: 'qc',
  initialState: { loading: false, data: [] },
  reducers: {
    setQCState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setQC: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { loadQC = 'qc/LOAD_QC', setQCState, setQC }: any = qcSlice.actions;

export default qcSlice.reducer;
