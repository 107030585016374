import Pako from 'pako';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import apiClient from '../axios';

export async function addBillApi(
  data: Record<string, any>,
  setAddDatabill: any,
  setViewOn: any
): Promise<any> {
  const endpoint = `/accounts/add`;

  return apiClient
    .post(endpoint, data)
    .then((res) => {
      if (res) {
        setAddDatabill(res?.data);
        setViewOn(true);
        //console.log('addBillApi', res.data);
      }
    })
    .catch((err) => {
      //console.log('hh', err);
    });
}

export const updateBillCache = async () => {
  const endpoint = `/accounts/reload/1`;

  apiClient
    .get(endpoint)
    .then((response) => {
      if (response) {
        triggerSuccessNotification('Bill Data Synced');
      }

      return response.data;
    })
    .catch(() => {
      triggerErrorNotification('Try again Later');
      return false;
    });
};

export async function getAllBills(reload: any): Promise<any> {
  return fetch(`${process.env.REACT_APP_BASE_URL}/uploads/billlist.json.gzip`, {
    mode: 'cors',
  })
    .then((res) => {
      return res.arrayBuffer().then((buf) => {
        const outBuf = Pako.inflate(buf);
        const str = new TextDecoder().decode(outBuf);
        const accData = JSON.parse(str);
        console.log('Do: ', accData);
        return accData;
      });

      // setAccountsData(accData?.data);
      // setAccountsEditData(accData?.data);

      // setAllChallanDataList(data);
      // setIsLoading(false);
    })
    .catch((e) => {
      // setIsLoading(false);
      return false;
    });
}

export async function getOrdersByCustomer(customer_id: any, orders: any): Promise<string> {
  const endpoint = `/orders/customer/${customer_id}/with-payment/${orders}`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getBillsByCustomerApi(customer_id: string): Promise<string> {
  const endpoint = `/accounts/customer/${customer_id}`;

  return apiClient
    .get(endpoint)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function updateBillApi(id: number, data: Record<string, any>): Promise<string> {
  const endpoint = `/accounts/${id}/`;

  return apiClient.put(endpoint, data);
}
