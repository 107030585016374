import { createSlice } from '@reduxjs/toolkit';

const reportsSlice = createSlice({
  name: 'reportsReducer',
  initialState: { loading: false },
  reducers: {
    setReports: (state: any, action: { payload: any }) => ({ ...state, ...action.payload }),
    // @ts-ignore
    setOrderProductionSum: (
      state: { data: { data: { map: (arg0: (item: any) => any) => never[] } } },
      action: { payload: { data: any[] } }
    ) => {
      const obj = action?.payload?.data?.reduce((accum, item) => {
        accum[item.order] = item.actual_production;
        return accum;
      }, {});
      if (state?.data?.data) {
        // @ts-ignore
        state.data.data =
          state?.data?.data?.map((item) =>
            item.id in obj ? { ...item, actual_production_sum: obj[item.id] } : item
          ) || [];
      }
    },
  },
});

export const {
  loadFactoryKeyMetricsOnTarget = 'reportReducer/loadFactoryKeyMetricsOnTarget',
  loadFactoryKeyMetricsProductionEfficiency = 'reportReducer/loadFactoryKeyMetricsProductionEfficiency',
  loadFactoryKeyMetricsActivityStatus = 'reportReducer/loadFactoryKeyMetricsActivityStatus',
  loadProductionMonitoringData = 'reportReducer/loadProductionMonitoringData',
  loadProductionSummaryData = 'reportReducer/loadProductionSummaryData',
  loadOutputRate = 'reportReducer/loadOutputRate',
  loadOutputRateByBuyer = 'reportReducer/loadOutputRateByBuyer',
  loadProductionLastDate = 'reportReducer/loadProductionLastDate',
  loadOrdersProductionSum = 'orders/loadOrdersProductionSum',
  setReports,
} = reportsSlice.actions;
export default reportsSlice.reducer;
