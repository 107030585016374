import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getColorFrontSideApi } from '../../../services/trims/colorFrontSideService';
import {
  loadColorFrontSide,
  setColorFrontSide,
  setColorFrontSideState,
} from '../reducer/colorFrontSideReducer';

function* LOAD_COLORFRONTSIDE({ payload }: { payload: { orderId: number } }) {
  //console.log('Color Front Side Order_Id', payload.orderId);
  yield put(setColorFrontSideState({ loading: true }));

  const colorFrontSide: Record<string, never> = yield call(() =>
    getColorFrontSideApi(payload.orderId)
  );
  if (colorFrontSide) {
    yield put(setColorFrontSide(colorFrontSide));
  }

  yield put(setColorFrontSideState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadColorFrontSide, LOAD_COLORFRONTSIDE)]);
}
