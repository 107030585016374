import { createSlice } from '@reduxjs/toolkit';
import { OrderProps } from '../../../types/states';

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    loading: false,
    data: {},
  },
  reducers: {
    setAccountState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setAccount: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  loadAccount = 'account/load',
  loadAccounts = 'accounts/load',
  addAccount = 'account/add',
  setAccount,
  setAccountState,
}: any = accountSlice.actions;

export default accountSlice.reducer;
