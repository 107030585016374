import { createSlice } from '@reduxjs/toolkit';
import { OrderProps } from '../../types/states';

const ordersCacheSlice = createSlice({
  name: 'cachedOrders',
  initialState: { loading: false, data: [] } as { loading: boolean; data: OrderProps[] },
  reducers: {
    setOrdersCacheState: (state, action) => ({ ...state, ...action.payload }),
    setOrdersCache: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  cacheOrders = 'cachedOrders/load',
  setOrdersCacheState,
  setOrdersCache,
}: any = ordersCacheSlice.actions;

export default ordersCacheSlice.reducer;
