import { createSlice } from '@reduxjs/toolkit';

const singleThreadSlice = createSlice({
  name: 'singleThread',
  initialState: { loading: false, data: [] as any },
  reducers: {
    setSingleThreadState: (state, action) => ({ ...state, ...action.payload }),
    setSingleThread: (state, action) => ({ ...state, ...action.payload }),
    addSingleThreadMessage: (state, action) => {
      const { conversation_id } = action.payload.data;
      if (action.payload.convId === conversation_id) {
        state.data.push(action.payload.data);
      }
    },
  },
});

export const {
  loadSingleThread = 'singleThread/loadSingleThread',
  setSingleThreadState,
  setSingleThread,
  addSingleThreadMessage,
}: any = singleThreadSlice.actions;

export default singleThreadSlice.reducer;
