import apiClient from './axios/toolsClient';

export async function getToolsProductionDataApi(
  step: string,
  {
    startDate,
    endDate,
    buyer,
    style,
  }: { startDate: string; endDate: string; buyer: string; style: string }
) {
  return apiClient
    .get(
      `/productions/steps/${step}/buyer/${buyer || 'all'}/style/${style || 'all'}/start_date/${
        startDate || 'all'
      }/end_date/${endDate || 'all'}`
    )
    .then((resp) => resp.data)
    .catch(() => false);
}

export async function addToolsProductionDataApi(requestBody: any) {
  return apiClient
    .post('/productions', requestBody)
    .then((resp) => resp.data)
    .catch(() => false);
}

export async function editToolsProductionDataApi(requestBody: any, productionId: number) {
  return apiClient
    .put(`/productions/${productionId}`, requestBody)
    .then((resp) => resp.data)
    .catch(() => false);
}

export async function deleteToolsProductionDataApi(productionId: number): Promise<any> {
  return apiClient
    .delete(`/productions/${productionId}`)
    .then(() => true)
    .catch(() => false);
}
