import React, { HTMLAttributes } from 'react';

//Size: xs, sm, rag(default), big
//Varient: solid
//Color: light, theme-primary, danger, primary
//icon
//noShadow

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  size?: 'xs' | 'sm' | 'rag' | 'big';
  variant?: 'solid' | 'outlined';
  color?: 'light' | 'theme-primary' | 'danger' | 'primary';
  className?: string;
  disabled?: boolean;
  icon?: string | true;
  noShadow?: boolean;
  count?: boolean | number;
  countColor?: 'primary' | 'success' | 'danger';
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: React.FC<IButtonProps> = ({
  children,
  size = 'rag',
  variant = 'outlined',
  color = 'light',
  className = '',
  disabled = false,
  icon = '',
  noShadow = true,
  count = false,
  countColor = 'primary',
  type,
  ...props
}) => {
  return (
    <button
      className={`omdButton size-${size} variant-${variant} ${
        icon ? 'icon' : icon
      } color-${color} ${className} ${disabled ? 'disabled' : ''} ${noShadow ? 'noShadow' : ''} ${
        count ? 'mt-10' : ''
      }`}
      disabled={disabled}
      type={type}
      {...props}
    >
      {children}
      {count && <span className={`countBadge color-${countColor ? countColor : ''}`}>{count}</span>}
    </button>
  );
};

export default Button;
