import { UsersByOrderProps } from '../types/states';
import apiClient from './axios';
export async function getUsersApi(): Promise<string> {
  return apiClient
    .get(`/users/`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
export async function getUsersByOrderApi(id: number): Promise<string> {
  return id
    ? apiClient
        .get(`/getuserbyorder/${id}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function addUsersToOrderApi(
  orderId: number,
  userId: number
): Promise<{ data: UsersByOrderProps }> {
  return orderId
    ? apiClient
        .post(`/user/invite/`, { order_ids: [orderId], user_ids: [userId] })
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
export async function removeUsersFromOrderApi(
  orderId: number,
  userId: number,
  permissionId: number
): Promise<{ data: UsersByOrderProps }> {
  return orderId
    ? apiClient
        .delete(`/orders/${orderId}/users/${userId}/permission/${permissionId}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : false;
}
