import { createSlice } from '@reduxjs/toolkit';

const commissionSlice = createSlice({
  name: 'commission',
  initialState: { loading: false, data: [] as any[] },
  reducers: {
    setCommissionState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setCommission: (state, action) => ({ ...state, ...action.payload }),
    addCommission: (state, action) => {
      state.data.push(action.payload);
    },
    removeCommission: (state, action) => {
      state.data = state?.data?.filter((item) => item.id !== action.payload.id);
    },
  },
});

export const {
  loadCommission = 'commission/loadCommission',
  deleteCommission = 'commission/deleteCommission',
  setCommissionState,
  setCommission,
  addCommission,
  removeCommission,
}: any = commissionSlice.actions;

export default commissionSlice.reducer;
