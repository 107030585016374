import apiClient from './axios';
import chatServerClient from './axios/chatServerClient';

export async function getNotificationApi(): Promise<string> {
  return apiClient
    .get(`/notifications`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function getNotificationUnseenApi(userId: number): Promise<string> {
  return '';
  return chatServerClient
    .get(`/users/${userId}/notifications/unseen`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
