import React from 'react';

const NotFound = (): JSX.Element => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100 w-100">
      <h1>404 Not Found</h1>
    </div>
  );
};

export default NotFound;
