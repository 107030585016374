import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getOrderStepApi } from '../../../services/trims/orderStepService';
import { loadOrderStep, setOrderStep, setOrderStepState } from '../reducer/orderStepReducer';

function* LOAD_ORDERSTEP({ payload }: { payload: { orderId: number } }) {
  //console.log('OrderId orderstep', payload.orderId);
  yield put(setOrderStepState({ loading: true }));

  const orderStep: Record<string, never> = yield call(() => getOrderStepApi(payload.orderId));
  if (orderStep) {
    yield put(setOrderStep(orderStep));
  }

  yield put(setOrderStepState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadOrderStep, LOAD_ORDERSTEP)]);
}
