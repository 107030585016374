import { ProductionProps } from '../types/states';
import apiClient from './axios';

export async function getProductionApi(order_id: number): Promise<string> {
  {
    //console.log(order_id);
  }
  return apiClient
    .get(`/production_details/${order_id}`)
    .then((response) => {
      //console.log('response data', response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addProductionApi(
  order_id: number,
  data: FormData
): Promise<{ data: ProductionProps }> {
  return apiClient.post(`/production_details/${order_id}`, data);
}

export async function deleteProductionApi(order_id: number): Promise<{ data: ProductionProps }> {
  return apiClient.delete(`/production_details/${order_id}`);
}
