import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getTodaysTasksApi } from '../../services/todaysTasksService';
import {
  loadTodaysTasks,
  setTodaysTasks,
  setTodaysTasksState,
} from '../reducers/todaysTasksReducer';

function* LOAD_TODAYS_TASKS({ payload }: { payload: { id: number } }) {
  yield put(setTodaysTasksState({ loading: true }));

  const tasks: Record<string, never> = yield call(() => getTodaysTasksApi(payload.id));

  if (tasks) {
    yield put(setTodaysTasks(tasks));
  }

  yield put(setTodaysTasksState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadTodaysTasks, LOAD_TODAYS_TASKS)]);
}
