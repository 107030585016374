export const MATERIAL_CATEGORY_OPTIONS = [
  { title: 'Yarn', value: 'yarn' },
  { title: 'Accessories', value: 'accessories' },
];

export const UNIT_OPTIONS = [
  { value: 'LBS', title: 'LBS' },
  { value: 'KGS', title: 'KGS' },
  { value: 'PICES', title: 'PICES' },
];

export const MATERIAL_TYPES = [
  { title: 'Order Stock', value: 'order_stock' },
  { title: 'General Stock', value: 'general_stock' },
];
export const backoffice = 5771;
export const basename = '';
// export const basename = '/console';
