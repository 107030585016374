import apiClient from './axios';

export async function getNotesApi(orderId: number): Promise<string> {
  return apiClient
    .get(`/notes/order/${orderId}`)
    .then((response: any) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addNotesApi(data: any): Promise<{ data: any }> {
  return apiClient.post(`/notes/`, data);
}
