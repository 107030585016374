import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getQCApi } from '../../../services/trims/qcService';
import { loadQC, setQC, setQCState } from '../../trims/reducer/qcReducer';

function* LOAD_QC({ payload }: { payload: { orderId: number } }) {
  //console.log(payload);
  yield put(setQCState({ loading: true }));

  const challan: Record<string, never> = yield call(() => getQCApi(payload.orderId));
  //console.log(challan);
  if (challan) {
    yield put(setQC(challan));
  }

  yield put(setQCState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadQC, LOAD_QC)]);
}
