import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getTasksApi, updateTaskApi } from '../../services/tasksService';
import {
  loadTasks,
  setTasks,
  setTasksState,
  updateTasks,
  updateTasksData,
} from '../reducers/tasksReducer';

function* LOAD_TASKS() {
  yield put(setTasksState({ loading: true }));

  const tasks: Record<string, never> = yield call(() => getTasksApi());

  if (tasks) {
    yield put(setTasks(tasks));
  }

  yield put(setTasksState({ loading: false }));
}

function* UPDATE_TASKS({
  payload,
}: {
  payload: { id: number; data: Record<string, never>; completeFunc: () => void };
}) {
  yield put(setTasksState({ loading: true }));

  const task: Record<string, never> = yield call(() => updateTaskApi(payload.id, payload.data));

  if (task) {
    yield put(updateTasksData(task));
    if (payload?.completeFunc) {
      payload?.completeFunc();
    }
  }

  yield put(setTasksState({ loading: false }));

  return task;
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadTasks, LOAD_TASKS), takeEvery(updateTasks, UPDATE_TASKS)]);
}
