//Interceptor servie for Socket

import axios from 'axios';
// import { logout } from "../../utils/authUtils";

const chatServerClient = axios.create({
  baseURL: process.env.REACT_APP_API_SOCKET_URL,
});

chatServerClient.interceptors.request.use((request) => {
  const userString = localStorage.getItem('user') || '';
  const user = JSON.parse(userString);
  request.headers.Authorization = 'Bearer ' + user.access;
  return request;
});

// chatServerClient.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     if (err.response.status === 401) {
//       logout();
//     }
//   }
// );

export default chatServerClient;
