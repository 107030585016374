import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DrawerMenuItem from '../common/navItem/DrawerMenuItem';
import { AuthProps } from '../../types/states';
import { toolsReportTableFields } from '../../libs/tools_report_table_field';
import React, { useState } from 'react';
import { basename } from '../../utils/constant';
import DrawerSubMenuItem from '../common/navItem/DrawerSubMenuItem';

type StateProps = {
  auth?: { data: AuthProps };
  steps?: {
    data: {
      id: number;
      company_id: number;
      step_name: string;
      created_at: string;
      updated_at: string;
    }[];
  };
};

const mapStateToProps = ({ auth, steps }: StateProps) => ({
  auth,
  steps,
});

interface IDrawerMenuProps extends StateProps {
  dispatch: any;
  socket?: any;
}

const DrawerMenu = ({ steps, auth }: IDrawerMenuProps & RouteComponentProps) => {
  let customerVisible = false;

  customerVisible =
    auth?.data?.user?.role == 'sales' ||
    auth?.data?.user?.role == 'customer-service' ||
    auth?.data?.user?.is_admin == true
      ? true
      : false;

  let challanVisible = false;
  challanVisible =
    auth?.data?.user?.role == 'logistics' ||
    auth?.data?.user?.role == 'accounts' ||
    auth?.data?.user?.is_admin == true
      ? true
      : false;

  let accountVisible = false;
  accountVisible =
    auth?.data?.user?.role == 'accounts' || auth?.data?.user?.is_admin == true ? true : false;

  let getpassAddVisible = false;
  getpassAddVisible =
    auth?.data?.user?.is_admin || (auth?.data?.user?.role == 'logistics') == true ? true : false;

  let getpassListVisible = false;
  getpassListVisible =
    auth?.data?.user?.is_admin ||
    auth?.data?.user?.role == 'customer-service' ||
    (auth?.data?.user?.role == 'logistics') == true
      ? true
      : false;

  const OrderTrackingVisible = true;

  let qcVisible = false;
  qcVisible = auth?.data?.user?.role == 'qc' || auth?.data?.user?.is_admin == true ? true : false;

  let productionVisible = false;
  productionVisible =
    auth?.data?.user?.role == 'production' || auth?.data?.user?.is_admin == true ? true : false;

  let rawMaterialVisible = false;
  rawMaterialVisible =
    auth?.data?.user?.role == 'store' || auth?.data?.user?.is_admin == true ? true : false;

  let srVisible = false;
  srVisible =
    auth?.data?.user?.role == 'sales' ||
    auth?.data?.user?.role == 'customer-service' ||
    auth?.data?.user?.role == 'product-development' ||
    auth?.data?.user?.is_admin == true
      ? true
      : false;

  let wrVisible = false;
  wrVisible =
    auth?.data?.user?.role == 'customer-service' || auth?.data?.user?.is_admin == true
      ? true
      : false;

  let smVisible = false;
  smVisible =
    auth?.data?.user?.role == 'product-development' || auth?.data?.user?.is_admin == true
      ? true
      : false;

  let configurationVisible = false;
  configurationVisible =
    auth?.data?.user?.role == 'product-development' || auth?.data?.user?.is_admin == true
      ? true
      : false;

  let billOfMaterialVisible = false;
  billOfMaterialVisible =
    auth?.data?.user?.role == 'production' ||
    auth?.data?.user?.role == 'store' ||
    auth?.data?.user?.is_admin == true
      ? true
      : false;

  return (
    <div className="drawerMenu">
      {auth?.data?.user?.is_admin && (
        <DrawerMenuItem
          link={`${basename}/`}
          title="Dashboard"
          className="mt-3"
          icon="fas fa-chart-line me-3"
        />
      )}

      <DrawerMenuItem title="Order Management" icon="fas fa-book me-3">
        <div className="pr-0 pl-0 pb-0">
          {srVisible && (
            <DrawerSubMenuItem
              icon="fas fa-handshake me-2"
              link={`${basename}/order-management/request`}
              title={'Sales Request'}
            />
          )}
          {smVisible && (
            <DrawerSubMenuItem
              icon="fas fa-flask me-2"
              link={`${basename}/order-management/sample`}
              title={'Sample Order'}
            />
          )}

          {wrVisible && (
            <DrawerSubMenuItem
              icon="fas fa-industry me-2"
              link={`${basename}/order-management/workorder`}
              title={'Work/Bulk Order'}
            />
          )}
          {challanVisible && (
            <DrawerSubMenuItem
              icon="fas fa-truck me-2"
              link={`${basename}/order-management/delivered`}
              title={'Delivered'}
            />
          )}
        </div>
      </DrawerMenuItem>

      {configurationVisible && (
        <DrawerMenuItem title="Configuration" icon="fas fa-book me-3">
          <div className="pr-0 pl-0 pb-0">
            <DrawerSubMenuItem
              icon="fas fa-ribbon me-2"
              link={`${basename}/labelReference`}
              title={'Label Reference'}
            />
          </div>
          <div className="pr-0 pl-0 pb-0">
            <DrawerSubMenuItem
              icon="fas fa-crutch me-2"
              link={`${basename}/configuration/brand`}
              title={'Brand / RBO'}
            />
            <DrawerSubMenuItem
              icon="fas fa-handshake me-2"
              link={`${basename}/configuration/sales_person`}
              title={'Sales Person'}
            />
            <DrawerSubMenuItem
              icon="fas fa-code-branch me-2"
              link={`${basename}/configuration/branch`}
              title={'LC/Bill Branch'}
            />
            <DrawerSubMenuItem
              icon="fas fa-underline me-2"
              link={`${basename}/configuration/uom`}
              title={'UOM'}
            />
            <DrawerSubMenuItem
              icon="fas fa-dna me-2"
              link={`${basename}/configuration/program_name`}
              title={'Program Name'}
            />
            <DrawerSubMenuItem
              icon="fas fa-spinner me-2"
              link={`${basename}/configuration/product_category`}
              title={'Product Category'}
            />
            <DrawerSubMenuItem
              icon="fas fa-thermometer me-2"
              link={`${basename}/configuration/production_method`}
              title={'Production Method'}
            />
            <DrawerSubMenuItem
              icon="fas fa-tape me-2"
              link={`${basename}/configuration/product_type`}
              title={'Product Type'}
            />
            <DrawerSubMenuItem
              icon="fas fa-sitemap me-2"
              link={`${basename}/configuration/information_type`}
              title={'Information Type'}
            />
            <DrawerSubMenuItem
              icon="fas fa-dollar-sign me-2"
              link={`${basename}/configuration/currency`}
              title={'Currency'}
            />
            <DrawerSubMenuItem
              icon="fas fa-industry me-2"
              link={`${basename}/configuration/manufacturing_unit`}
              title={'Manufacturing Unit'}
            />
            <DrawerSubMenuItem
              icon="fas fa-signal me-2"
              link={`${basename}/configuration/status`}
              title={'Status'}
            />
            <DrawerSubMenuItem
              icon="fas fa-code me-2"
              link={`${basename}/configuration/developed_by`}
              title={'Developed By'}
            />
            <DrawerSubMenuItem
              icon="fas fa-record-vinyl me-2"
              link={`${basename}/configuration/varnish`}
              title={'Varnish'}
            />
            <DrawerSubMenuItem
              icon="fas fa-snowflake me-2"
              link={`${basename}/configuration/lamination`}
              title={'Lamination'}
            />
            <DrawerSubMenuItem
              icon="fas fa-anchor me-2"
              link={`${basename}/configuration/emboss`}
              title={'Emboss'}
            />
            <DrawerSubMenuItem
              icon="fas fa-skull me-2"
              link={`${basename}/configuration/deboss`}
              title={'Deboss'}
            />
            <DrawerSubMenuItem
              icon="fas fa-map-pin me-2"
              link={`${basename}/configuration/foil`}
              title={'Foil'}
            />
            <DrawerSubMenuItem
              icon="fas fa-eye me-2"
              link={`${basename}/configuration/eyelet`}
              title={'Eyelet'}
            />
            <DrawerSubMenuItem
              icon="fas fa-oil-can me-2"
              link={`${basename}/configuration/crease`}
              title={'Crease'}
            />
            <DrawerSubMenuItem
              icon="fas fa-cross me-2"
              link={`${basename}/configuration/cross_sic`}
              title={'Cross Sic'}
            />
            <DrawerSubMenuItem
              icon="fas fa-mitten me-2"
              link={`${basename}/configuration/glue`}
              title={'Glue'}
            />
            <DrawerSubMenuItem
              icon="fas fa-street-view me-2"
              link={`${basename}/configuration/string`}
              title={'String'}
            />
          </div>
        </DrawerMenuItem>
      )}

      {customerVisible && (
        <DrawerMenuItem
          icon="fas fa-people-arrows me-2"
          link={`${basename}/configuration/customer`}
          title={'Customer'}
        />
      )}

      {rawMaterialVisible && (
        <DrawerMenuItem title="Raw Material" icon="fas fa-book me-3">
          <DrawerSubMenuItem
            icon="fas fa-street-view me-2"
            link={`${basename}/raw-material/raw-material-category`}
            title={'Raw Material Category'}
          />
          <DrawerSubMenuItem
            icon="fas fa-street-view me-2"
            link={`${basename}/raw-material/raw-material-sub-category`}
            title={'Raw Material Sub Category'}
          />
          <DrawerSubMenuItem
            icon="fas fa-street-view me-2"
            link={`${basename}/raw-material/raw-material-list`}
            title={'Raw Material List'}
          />
        </DrawerMenuItem>
      )}
      {billOfMaterialVisible && (
        <DrawerMenuItem
          link={`${basename}/bill_of_material`}
          title="Bill of Material"
          icon="fas fa-recycle me-3"
        />
      )}
      {productionVisible && (
        <DrawerMenuItem
          link={`${basename}/production`}
          title="Production"
          icon="fas fa-industry me-3"
        />
      )}

      {qcVisible && <DrawerMenuItem link={`${basename}/qc`} title="QC" icon="fas fa-book me-3" />}

      {challanVisible && (
        <DrawerMenuItem title="Challan" icon=" fas fa-solid fa-receipt me-3">
          <div className="pr-0 pl-0 pb-0">
            {auth?.data?.user?.role !== 'accounts' && (
              <DrawerSubMenuItem
                icon="fas fa-indent me-2"
                link={`${basename}/challan/add_challan`}
                title={'Add Challan'}
              />
            )}

            <DrawerSubMenuItem
              icon="fas fa-border-all me-2"
              link={`${basename}/challan/challan_list`}
              title={'Challan List'}
            />
          </div>
        </DrawerMenuItem>
      )}

      {accountVisible && (
        <DrawerMenuItem title="Accounts" icon="fas fa-user-circle me-2">
          <div className="pr-0 pl-0 pb-0">
            <DrawerSubMenuItem
              icon="fas fa-money-bill me-2"
              link={`${basename}/account/add_bill`}
              title={'Add Bill'}
            />
            <DrawerSubMenuItem
              icon="fas fa-check-square me-2 me-2"
              link={`${basename}/account/bill_list`}
              title={'Bill List'}
            />
          </div>
        </DrawerMenuItem>
      )}

      {(getpassAddVisible || getpassListVisible) && (
        <DrawerMenuItem title="Gate Pass" icon="fas fa-file-invoice me-2">
          <div className="pr-0 pl-0 pb-0">
            {getpassAddVisible && (
              <DrawerSubMenuItem
                icon="fas fa-money-bill me-2"
                link={`${basename}/gatepass/add_gate_pass`}
                title={'Add Gate Pass'}
              />
            )}
            {getpassListVisible && (
              <DrawerSubMenuItem
                icon="fas fa-check-square me-2 me-2"
                link={`${basename}/gatepass/gate_pass_list`}
                title={'Gate Pass List'}
              />
            )}
          </div>
        </DrawerMenuItem>
      )}

      {OrderTrackingVisible && (
        <DrawerMenuItem title="Order Tracking" icon="fas fa-map-marked-alt me-2">
          <div className="pr-0 pl-0 pb-0">
            <DrawerSubMenuItem
              icon="fas fa-solid fa-briefcase me-2"
              link={`${basename}/orderTracking/all_job`}
              title={'All Job'}
            />
            <DrawerSubMenuItem
              icon="fas fa-tasks me-2"
              link={`${basename}/orderTracking/in_hand_job`}
              title={'In Hand Job'}
            />
          </div>
        </DrawerMenuItem>
      )}
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(DrawerMenu));
