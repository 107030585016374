import { BTBProps } from '../types/states';
import apiClient from './axios';

export async function getBTBApi(order_id: number): Promise<string> {
  return apiClient
    .get(`/orders/${order_id}/btb/`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}

export async function addBTBApi(order_id: number, data: FormData): Promise<{ data: BTBProps }> {
  return apiClient.post(`/orders/${order_id}/btb/`, data);
}
export async function updateBTBApi(
  order_id: number,
  data: { id: number; status: 1 | 0 }
): Promise<{ data: BTBProps }> {
  return apiClient.put(`/orders/${order_id}/btb/`, data);
}
export async function btbLimitApi(
  order_id: number,
  data: { order_limit: number }
): Promise<{ data: BTBProps }> {
  return apiClient.post(`/orders/${order_id}/btblimit/`, data);
}
