import { all, put, takeEvery, call } from 'redux-saga/effects';
import { getItemSpecificationApi } from '../../../services/trims/itemSpecificationService';
import {
  loadItemSpecification,
  setItemSpecification,
  setItemSpecificationState,
} from '../reducer/itemSpecificationReducer';

function* LOAD_ITEMSPECIFICATION({ payload }: { payload: { orderId: number } }) {
  //console.log('OrderId itemSpecification', payload.orderId);
  yield put(setItemSpecificationState({ loading: true }));

  const itemSpecification: Record<string, never> = yield call(() =>
    getItemSpecificationApi(payload.orderId)
  );
  if (itemSpecification) {
    yield put(setItemSpecification(itemSpecification));
  }

  yield put(setItemSpecificationState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([takeEvery(loadItemSpecification, LOAD_ITEMSPECIFICATION)]);
}
