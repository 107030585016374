import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getToolsCostSheetApi } from '../../services/toolsCostSheetService';
import {
  loadCostSheetData,
  setCostSheetData,
  setCostSheetState,
} from '../reducers/toolsCostSheetReducer';

function* LOAD_COST_SHEET() {
  yield put(setCostSheetState({ loading: true }));
  // @ts-ignore
  const costSheetData = yield call(() => getToolsCostSheetApi());
  if (costSheetData) {
    yield put(setCostSheetData(costSheetData));
  }
  yield put(setCostSheetState({ loading: false }));
}

export default function* rootSaga() {
  yield all([takeEvery(loadCostSheetData, LOAD_COST_SHEET)]);
}
