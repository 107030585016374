import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    loading: false,
    data: { id: 0 },
  },
  reducers: {
    setOrderState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setOrder: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { loadOrder = 'order/load', setOrder, setOrderState }: any = orderSlice.actions;

export default orderSlice.reducer;
