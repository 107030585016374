import { createSlice } from '@reduxjs/toolkit';

const colorFrontSideSlice = createSlice({
  name: 'colorFrontSide',
  initialState: { loading: false, data: [] },
  reducers: {
    setColorFrontSideState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setColorFrontSide: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadColorFrontSide = 'colorFrontSide/LOAD_COLORFRONTSIDE',
  setColorFrontSideState,
  setColorFrontSide,
}: any = colorFrontSideSlice.actions;

export default colorFrontSideSlice.reducer;
