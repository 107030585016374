import store from '../store/store';

export const getThreadUsers = (type: 'DM' | 'GM' | 'GROUP'): number[] => {
  const state = store.getState().threads.data;
  const userString = localStorage.getItem('user') || '';
  const currentUser = JSON.parse(userString).user.id;
  return state
    ? state.reduce(
        (
          accum: Record<string, never>[],
          item: { conversation_type: 'DM' | 'GM' | 'GROUP'; members: Record<string, never>[] }
        ) => {
          if (type) {
            if (type === item.conversation_type) {
              return [...accum, item.members?.filter((item) => item.id !== currentUser)[0]?.id];
            }
            return accum;
          }
          return [...accum, item.members?.filter((item) => item.id !== currentUser)[0]?.id];
        },
        []
      )
    : [];
};
export function getActiveThread(): Record<string, never> {
  const state = store.getState().threads;

  return state.activeThread;
}
