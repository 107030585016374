import { all, put, takeEvery, call } from 'redux-saga/effects';
import {
  deleteProductionDataApi,
  getProductionDataApi,
} from '../../services/productionDataService';
import { triggerErrorNotification, triggerSuccessNotification } from '../../utils/notifications';
import {
  deleteProductionData,
  loadProductionData,
  removeProductionData,
  setProductionData,
  setProductionDataState,
} from '../reducers/productionDataReducer';

function* LOAD_PRODUCTION_DATA({ payload }: { payload: { id: number } }) {
  yield put(setProductionDataState({ loading: true }));

  const tasks: Record<string, never> = yield call(() => getProductionDataApi(payload.id));
  if (tasks) {
    yield put(setProductionData(tasks));
  }

  yield put(setProductionDataState({ loading: false }));
}

function* DELETE_PRODUCTION_DATA({ payload }: { payload: { id: number } }) {
  yield put(setProductionDataState({ loading: true }));

  // eslint-disable-next-line no-restricted-globals
  if (confirm('Are you sure you want to delete this?')) {
    const tasks: Record<string, never> = yield call(() => deleteProductionDataApi(payload.id));
    if (tasks) {
      yield put(removeProductionData(payload.id));
      triggerSuccessNotification('Successfully deleted this data.');
    } else {
      triggerErrorNotification('There is some error.');
    }
  }

  yield put(setProductionDataState({ loading: false }));
}

export default function* rootSaga(): any {
  yield all([
    takeEvery(loadProductionData, LOAD_PRODUCTION_DATA),
    takeEvery(deleteProductionData, DELETE_PRODUCTION_DATA),
  ]);
}
