import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../button/Button';

interface IDrawerMenuItemProps {
  title?: string;
  link?: string;
  icon: any;
}

const DrawerSubMenuItem: React.FC<RouteComponentProps & IDrawerMenuItemProps> = ({
  title,
  link,
  history,
  icon,
}) => {
  return (
    <div
      className="pr-0 pl-0 pb-0"
      style={{
        backgroundColor: history?.location?.pathname === link ? 'rgb(36 120 143 / 1)' : '',
      }}
    >
      <Link to={link || '#'}>
        <Button type="button">
          <i className={icon}></i> {title}
        </Button>
      </Link>
    </div>
  );
};
export default withRouter(DrawerSubMenuItem);
