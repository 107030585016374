import { createSlice } from '@reduxjs/toolkit';

const productionSlice = createSlice({
  name: 'production',
  initialState: { loading: false, data: [] },
  reducers: {
    setProductionState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setProduction: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  loadProduction = 'production/LOAD_PRODUCTION',
  setProductionState,
  setProduction,
}: any = productionSlice.actions;

export default productionSlice.reducer;
