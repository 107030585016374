import { CommissionProps } from '../types/states';
import apiClient from './axios';

export async function getcommissionApi(order_id: number): Promise<string> {
  return order_id
    ? apiClient
        .get(`/orders/${order_id}/commission/`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return false;
        })
    : {};
}

export async function addcommissionApi(
  order_id: number,
  data: Record<string, never>
): Promise<{ data: CommissionProps }> {
  return apiClient.post(`/orders/${order_id}/commission/`, data);
}

export async function deleteCommissionApi(order_id: number, commissionId: number): Promise<string> {
  return apiClient
    .delete(`/orders/${order_id}/commission/${commissionId}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
