import apiClient from './axios/omdClient';
export async function getContactsApi(): Promise<string> {
  return apiClient
    .get(`/contacts/`)
    .then((response: any) => {
      // //console.log(response.data);
      return response.data;
    })
    .catch(() => {
      return false;
    });
}
